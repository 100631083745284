export const locale = {
    lang: 'en',
    data: {
        'DIGITAL_FILE': {
            'MY_FILES': 'My files',
            'SEARCH': 'Search folder',
            'SIDEBAR': {
                'MAIN': {
                    'TITLE': 'ADOC',
                    'MY_FILES': 'My files',
                    'STARRED': 'Starred',
                    'SHARRED': 'Sharred',
                    'RECENT': 'Recent',
                }
            },
            'LIST': {
                'NAME': 'Name',
                'TYPE': 'Type',
                'OWNER': 'Owner',
                'SIZE': 'Size',
                'MODIFIED': 'Modified',
                'FOLDER': 'Folder',
                'FILE': 'File',
                'ADD_FILE': 'Add file',
                'NO_FILES': 'There are no documents. Create one to get started.',
                'NO_FOLDERS': 'There are no folders. Create one to get started.',
            },
            'DETAILS': {
                'SIGN_STATUS': 'Sign status',
                'OF': 'of',
                'COMPLETED': 'completed.',
                'SIGNERS': 'Sirngers',
                'EDITED': 'Edited',
                'INFO': 'Info',
                'TYPE': 'Type',
                'SIZE': 'Size',
                'LOCATION': 'Location',
                'OWNER': 'Owner',
                'MODIFIED': 'Modified',
                'OPENED': 'Opened',
                'CREATED': 'Created',
                'SHARE': 'Share',
                'FOLDER': 'Folder',
                'FILE': 'File',
                'DELETE': 'Delete',
                'SIGN': 'Sign',
                'PUBLIC': 'Public',
                'PRIVATE': 'Private',
                'PUBLIC_EXPLAIN': 'You can access to the folder without any private code.',
                'PRIVATE_EXPLAIN': 'You can only access to the folder with a private code.',
                'PRIVATE_CODE': 'Private code',
                'SIGNED': 'Signed',
                'DOWNLOAD': 'Download',
                'COPY_LINK': 'Copy link',
                'SIGN_LINK': 'Copy sign link',
                'COPIED_LINK': 'Copied',
                'VIEW_CHECKLIST': 'View checklist',
                'ENABLE_SIGN': 'Enable sign',
                'REACTIVATE': 'Reactivate',
                'CANCEL_SIGN': 'Cancel',
                'ADD_CHECKLIST': 'Add checklist',
                'CONFIRM_DELETE_FOLDER': 'Do you want to delete this folder?',
                'CONFIRM_DELETE_FILE': 'Do you want to delete this file?',
                'TEXT_CONFIRM_DELETE': 'This may take several minutes, do not reload the page in the process.',
                'REMOVED_FOLDER': 'Folder removed successfully.',
                'REMOVED_FILE': 'File removed successfully.',
                'BAD_PARAMETERS': 'The action could not be performed. Please reload the page.',
                'CLIENT_NOT_FOUND': 'The action could not be performed. Please reload the page.',
                'FOLDER_NOT_FOUND': 'Folder not found or removed.',
                'DOCUMENT_NOT_FOUND': 'Document not found or removed.',
                'UNAVAILABLE_SERVICE': 'The service is not available at this time. Please try again in a few minutes. We apologize for the inconveniences.',
                'NO_PDF_FILE': 'The file must be PDF to enable signing.',
                'PROTECTED_PDF': 'PDF file is protected or encrypted, so signature cannot be enabled.',
            },
            'DIALOGS': {
                'FOLDER_FORM': {
                    'TITLE': "New folder",
                    'OWNER': "Owner",
                    'NAME': "Folder name",
                    'CREATE': "Create",
                    'VIEW_CHECKLIST': "View checklist",
                    'SELECT_TO_VIEW_CHECKLIST': "Select to display the checklist",
                    'SELECT': "Select",
                    'BACK': "Back",
                    "CHECKLIST": "Checklist",
                    'OTHERS': "Others",
                    'ERRORS': {
                        'UNKNOWN': 'Service not available at this time. Please try again in a few minutes.',
                        'NO_ID_CLIENT': 'You need to recharge to perform this action.',
                        'DELETED_CURRENT_FOLDER': 'The folder, in which you are trying to create another folder, has been deleted. Reload the page, please.',
                        'NAME_EXISTS': 'Another folder with the same name already exists.',
                    },
                },
                'CHECKLIST_FORM': {
                    'TITLE': "Checklist",
                    'CREATE': "Create",
                    'VIEW_CHECKLIST': "View checklist",
                    'SELECT_TO_VIEW_CHECKLIST': "Select to display the checklist",
                    'SELECT': "Select",
                    'ADD_CHECKLIST': 'Add',
                    'BACK': "Back",
                    'SAVE': "Save",
                    'CLOSE': "Close",
                    'CANCEL': "Cancel",
                    "CHECKLIST": "Checklist",
                    'WRITE_TITLE': "Title",
                    'WRITE_DESCRIPTION': "Description",
                    'NO_CHECKLIST_SELECTED': 'Checklist has not been selected for this folder.',
                    'ERRORS': {
                        'UNKNOWN': 'Service not available at this time. Please try again in a few minutes.',
                        'NO_ID_CLIENT': 'You need to recharge to perform this action.',
                        'DELETED_CURRENT_CHECKLIST': 'The folder, in which you are trying to create a checklist, has been deleted. Reload the page, please.',
                    },
                },
                'FILE_FORM': {
                    'TITLE': "New file",
                    'OWNER': "Owner",
                    'NAME': "File name",
                    'UPLOAD': "Upload",
                    'FILE': "File",
                    'CHOOSE_FILE': "Choose a file",
                    'ERRORS': {
                        'UNKNOWN': 'Service not available at this time. Please try again in a few minutes.',
                        'NO_CLIENT_ID': 'You need to recharge to perform this action.',
                        'NO_FOLDER_ID': 'You need to recharge to perform this action.',
                        'NO_DOCUMENT_NAME': 'You must enter a file name.',
                        'FILE_ERROR': 'The file was not detected correctly. Please select it and try again.',
                        'NO_FILE': 'You must specify the file you want to upload.',
                        'FOLDER_NOT_FOUND': 'The folder, in which you are trying to create a file, has been deleted. Reload the page, please.',
                        'UNKNOWN_FILE_FORMAT': 'The file format has not been recognized, please choose a different one.',
                        'CLIENT_NOT_FOUND': 'You need to recharge to perform this action.',
                        'NAME_DOCUMENT_EXISTS': 'Another file with the same name already exists in this folder.',
                        'UNAVAILABLE_SERVICE': 'Service not available at this time. Please try again in a few minutes.',
                    },
                },
                'SHARE_EMAIL': {
                    'TITLE': 'Share by email',
                    'ADD_EMAIL': 'Add another email',
                    'EMAIL_INPUT': 'Write an email',
                    'SHARE': 'Share',
                    'EMAIL_ERROR': 'The email is not valid, please check your data.',
                    'SHARED_SUCCESSFULLY': 'Emails have been sent correctly.',
                    'UNAVAILABLE_SERVICE': 'The service is not available at this time. Please try again in a few minutes. We apologize for the inconveniences.',
                },
                'CREATE_SIGN': {
                    'TITLE': 'Create new signature',
                    'TITULO': 'Title',
                    'DESCRIPTION': 'Description',
                    'SENDING_TYPE': 'Signature type',
                    'LINK': 'Individual link',
                    'EMAIL': 'Email',
                    'EMAIL_INPUT': 'Write an email',
                    'LINK_NUMBER': 'Links number',
                    'SEND': 'Send',
                    'DOCUMENT_TYPE': 'Select a signature type',
                    'MULTISIGNATURE':'Multi-signature',
                    'GENERATE_LINK':'Links number',
                    'SIGNATORIES_NUMBER':'Signatories number',
                    'SELECT_SIGNATURIES_NUMBER': 'Select the total signatories number',
                    'NEXT': 'Next',
                    'BACK': 'Back',
                    'SELECT_AN_OPTION': 'Select an option',
                    'FINISH': 'Finish'
                }
            },
        }
    }
};
