<!-- <div class="dialog-content-wrapper">
    <div>
        <mat-toolbar class="mat-accent m-0">
            <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
                <span class="title dialog-title">
                    {{'SHARE_WHATSAPP.TITLE' | translate}}
                    <img class="image" src="../../../../../../assets/icons/social-networks/whatsapp.png" />
                </span>
                <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-toolbar-row>
        </mat-toolbar>
    </div>

    <div mat-dialog-content class="m-0 {{loading ? 'cero-padding' : 'mat-padding'}}" fusePerfectScrollbar>

        <h1 class="title_center" mat-dialog-title>
                    {{'SHARE_WHATSAPP.TITLE' | translate}}
                    <img class="image" src="../../../../../../assets/icons/social-networks/whatsapp.png" />
            </h1>
        <div class="message" mat-dialog-content layout="row" layout-align="center center" flex>
            <p style="text-align: justify;">
                <strong>{{'SHARE_WHATSAPP.WRITE_NUMBER' | translate}}</strong><br />
                <em>{{ 'SHARE_WHATSAPP.ADDITIONAL_INFO' | translate }}</em>
            </p>
        </div>

        <div class="message" mat-dialog-content layout="row" layout-align="center center" flex>
            <input type="text" class="minput" placeholder="{{ 'SHARE_WHATSAPP.PHONE_NUMBER' | translate }}"
                [(ngModel)]="phoneNumber">
        </div>

        <br>

        <div class="buttons">

            <button mat-button (click)="dialogRef.close(false)">{{'SHARE_WHATSAPP.BACK' | translate}}</button>

            <button mat-raised-button class="mat-accent"
                (click)="enviarMensaje()">{{'SHARE_WHATSAPP.SEND' | translate}}</button>

        </div>
    </div>
</div> -->

<div class="dialog-content-wrapper">
    <div>
        <mat-toolbar class="mat-accent m-0">
            <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
                <span class="title dialog-title">
                    <img class="image" src="../../../../../../assets/icons/social-networks/whatsapp.png" />
                    {{'SHARE_WHATSAPP.TITLE' | translate}}
                </span>
                <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-toolbar-row>
        </mat-toolbar>
    </div>

    <div mat-dialog-content class="m-0 mat-padding" fusePerfectScrollbar>

        <!-- El formulario dispondrá de diversos pasos, cada uno se mostrará de manera independiente -->
        <form name="whatsappForm" [formGroup]="whatsappForm" class="whatsapp-form" fxLayout="column" fxFlex>

            <div class="interior">

                <mat-checkbox class="mt-24" formControlName="conNumero" [(ngModel)]="conNumero">
                    {{ 'SHARE_WHATSAPP.USE_CUSTOM_NUMBER' | translate }}
                </mat-checkbox>
                <br />
                <mat-form-field appearance="outline" class="{{ conNumero ? 'muestra' : 'oculto'}}">
                    <mat-label>{{ 'SHARE_WHATSAPP.WRITE_NUMBER' | translate }}</mat-label>
                    <input [(ngModel)]="phoneNumber" formControlName="phoneNumber" matInput
                        placeholder="{{ 'SHARE_WHATSAPP.PHONE_NUMBER' | translate }}" type="text"
                        (keypress)="eventHandler($event)" required>
                </mat-form-field>
                <div class="message {{ conNumero ? 'muestra' : 'oculto'}}" mat-dialog-content layout="row"
                    layout-align="center center" flex>
                    <em>{{ 'SHARE_WHATSAPP.ADDITIONAL_INFO' | translate }}</em>
                </div>
                <div class="message {{ !conNumero ? 'muestra' : 'oculto'}}" mat-dialog-content layout="row"
                    layout-align="center center" flex>
                    <p style="text-align: justify;">
                        <em>{{ 'SHARE_WHATSAPP.CHOOSE_NUMBER' | translate }}</em>
                    </p>
                </div>

            </div>
        </form>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="center center">
        <div>
            <button mat-raised-button color="accent" (click)="enviarMensaje()" class="save-button"
                [disabled]="conNumero ? whatsappForm.invalid : false">
                {{ 'SHARE_WHATSAPP.SEND' | translate }}
            </button>
        </div>
    </div>
</div>