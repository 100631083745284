<div class="dialog-content-wrapper">
    <div>
        <mat-toolbar class="mat-accent m-0">
            <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
                <span class="title dialog-title">
                    <mat-icon>contact_mail</mat-icon>
                    {{'DIGITAL_FILE.DIALOGS.SHARE_EMAIL.TITLE' | translate}}
                </span>
                <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-toolbar-row>
        </mat-toolbar>
    </div>

    <div mat-dialog-content class="m-0 {{loading ? 'cero-padding' : 'mat-padding'}}" fusePerfectScrollbar>
        <form name="emailForm" [formGroup]="emailForm" class="email-form" fxLayout="column" fxFlex>
            <div class="escribirCorreos {{ !loading ? 'muestra' : 'oculto'}}">
                <div formArrayName="correos">
                    <ng-container *ngFor="let correo of correos.controls; let i = index">
                        <ng-container [formGroupName]="i">
                            <mat-form-field appearance="outline">
                                <mat-label>{{ 'DIGITAL_FILE.DIALOGS.SHARE_EMAIL.EMAIL_INPUT' | translate }}</mat-label>
                                <input matInput name="correo" formControlName="correo" type="email" required>
                                <div *ngIf="correos.length > 1" class="borrar">
                                    <button mat-icon-button (click)="removeEmail(i)" aria-label="Delete"
                                        matTooltip="Delete">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </mat-form-field>
                        </ng-container>
                    </ng-container>
                </div>
                <!-- ADD EMAIL BUTTON -->
                <button class="boton-correo" mat-fab (click)="addEmail()" type="button" aria-label="Add event">
                    <mat-icon>add</mat-icon> {{ 'DIGITAL_FILE.DIALOGS.SHARE_EMAIL.ADD_EMAIL' | translate }}
                </button>
                <!-- / ADD EMAIL BUTTON -->
            </div>
            <!-- LOADING -->
            <div class="{{ loading ? 'muestra' : 'oculto'}}">
                <app-loading></app-loading>
            </div>
            <!-- FIN LOADING -->
        </form>
    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="center center">
        <div>
            <button mat-raised-button color="accent" (click)="enviarMensaje()" class="save-button {{ !loading ? 'muestra' : 'oculto'}}">
                {{ 'DIGITAL_FILE.DIALOGS.SHARE_EMAIL.SHARE' | translate }}
            </button>
        </div>
    </div>
</div>