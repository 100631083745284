export const locale = {
    lang: 'es',
    data: {
        'DIALOG': {
            'SUCCESS': 'Éxito',
            'WARNING': 'Advertencia',
            'ERROR': 'Error',
            'CANCEL': 'Cancelar',
            'BACK': 'Volver',
            'ACCEPT': 'Aceptar',
            'INFO': 'Info',
            'TARJETA': "Método Pago",
            'FACTURACION': "Facturación",
            'TERMINOS': "Términos y Condiciones",
            'CODIGO': 'Código Promocional',
            'CARD': 'Tarjeta: ',
            'RENOVACION':'Renovación Mensual',
            'IMPORTE':'Importe Total: ',
            'CONFIRMAR':'Confirmar Datos',
            'ACEPTAR_TERMINOS':'Acepto los Términos y Condiciones',
            'IVA':' € (IVA incluido)'
        },
      
    }
};
