export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'HOME': 'Home',
            'SECTIONS': 'Sections',
            'TOOLS': 'Tools',
            'SINPA': 'Sinpa',
            'DOCUMENTS'  : 'Electronic Signature',
            'EVENTS'  : 'Events',
            'TEMPLATES'    : 'Create a signature',
            'LIST_TEMPLATES'   : 'List of documents',
            'CREATE_TEMPLATES'     : 'Create document',
            'VERIFICATION':'Verify document',
            'SETTINGS': 'Settings',
            'USER_SETTINGS': 'User settings',
            'BIOMETRIC_SIGNATURES': 'View Biometric Signatures',
            'QUICK_SIGNATURES': 'View Easy Signatures',
            'DIGITAL_FILE': 'ADOC-Shared files',
            'TIME_CONTROL': 'Time control',
            'CONTACT': 'Contact',
            'CONTACT_EMAIL': 'Contact email',
            'HELP': 'Help',
            'OTHER_SECTIONS': 'Other sections',
            'MANUAL': 'Manual',
            'VIDEOS': 'Videos',
            'SBK_IDENTITY': 'SBK Identity',
            'MAIL_BUROFAX': 'Mailburofax',
            'VIDEO': 'Video',
            'BETA': 'Beta',
            'ONE_SHOT': 'One Shot signature',
            'NEW': 'New'
        }
    }
};
