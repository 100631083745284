export const locale = {
    lang: 'en',
    data: {
        'TOOLBAR': {
            'LOGOUT': 'Logout',
            'MY_PROFILE': 'My profile',
            'MY_SUBSCRIPTION': 'My Susbcription',
            'SUBSCRIPCION': 'Subscriptions',
            'TEXTO': 'Choose the best Subscription for your business.',
            'BUTTON': 'Subscribe',
            'CANCELBUTTON': 'Cancel',
            'METHOD': 'Payment Method',
            'PLANACTUAL':'CURRENT PLAN',
            'MES':'Month',
            'IMPUESTO':'Indirect taxes not included',
            'LIMITE':'Limit: ',
            'RENOVACION':'Renewal: ',
            'CANCELARA':'Will be cancelled: ',
            'FIRMAS:':'Signatures Done: ',
            'CANCELADA':'SUBSCRIPTION CANCELED!'
        },
        'STYLE1': {
            'NEXT': 'Next',
            'IVA':' € (VAT included)',
            'CHANGEPAY':'Change Payment Method', // Cambiar método de Pago
            'PAYTEXT':'Plese, confirm Payment Method', //Por favor, confirme su Método Pago''
            'NOPAY':'You have not been assigned a payment method. You will be redirected to your profile to enter your Payment Method', //  'No tiene asignado un método de pago. Se le redigirá a su perfil para introducir su Método de Pago' 
            'FACTURACIONCHANGE': 'Change Billing data', //'Cambiar Datos Facturación
            'CODE':'If you have a Promotional Code, indicate it',
            'CODEOK':'Accepted Promotional Code',
            'CODEBAD':'Promotional code does not exist',
            'TERMS':'Accept the terms and conditions',
            'SUBSCRIBE':'Subscribe',
            'INFERIOR':'You are going to switch to a Lower plan. You must contact Sales. ',
            'SUPERIOR':'You will change to a higher plan.',
            'SUBSCRIBECHANGEOK':'The subscription change has been successful.',
            'SUBSCRIBECHANGEBAD':'An error occurred while changing the product subscription.',
            'SUBSCRIBEOK':'Subscription was successful'
        },
        'PROFILE': {
             'UPDATEOK': 'Data updated successfully.',
             'UPDATEBAD': 'Error Updating Client',
             'UPDATEPASSWORDBAD': 'The original password is not correct',
             'PAYMENT': 'Do you want to update the Payment method?',
             'PAYMENTNODATA': 'No payment method data has been modified',
             'PAYMENTBAD': 'An error occurred while updating your payment method. Review the data. ',
             'PAYMENTOK': 'The Payment method has been successfully updated',
        }
    }
};
