import { Injectable } from '@angular/core';

@Injectable()
export class ConfigService {
  constructor() {}

  public configFace() {
    let urlPrefix = 'http://localhost:14500/api/aivoz/';
    // let urlPrefix = 'http://localhost:8081/';
    let urlAPI = '';
    return {
      restUrlPrefix: urlPrefix + urlAPI
    };
  }
 
  public configSmart() {
    
    let urlPrefix = 'https://api.aivoz.es/api/aivoz/';
    // let urlPrefix = 'https://apiface.smartbiometrik.com/';
    //let urlPrefix = 'http://localhost:14000/';
    // let urlPrefix = 'http://localhost:8080/';
    let urlAPI = '';
    return {
      restUrlPrefix: urlPrefix + urlAPI
    };
  }
}
