import { ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseNavigationItem } from '@fuse/types';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { UtilityProvider } from 'app/providers/utility/utility';
import { GlobalService } from 'app/services/globalService';
import { Cliente } from 'app/models/data.model';
import { Router } from '@angular/router';
import { typePropertyIsNotAllowedMsg } from '@ngrx/store/src/models';

@Component({
    selector   : 'fuse-nav-vertical-item',
    templateUrl: './item.component.html',
    styleUrls  : ['./item.component.scss']
})
export class FuseNavVerticalItemComponent implements OnInit, OnDestroy
{
    @HostBinding('class')
    classes = 'nav-item';

    @Input()
    item: FuseNavigationItem;

    esProvisional: boolean;

    esCampaign: boolean;

    activo: boolean;

    cliente: Cliente;

    // Private
    private _unsubscribeAll: Subject<any>;
    desactivado: boolean;

    /**
     * Constructor
     */

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private utils: UtilityProvider,
        private globalService: GlobalService,
        private router: Router,
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.cliente = this.globalService.cliente;
        if (this.item.id == 'oneShot' && !this.cliente.firmaOneShot) {
            this.item.hidden = true;
        }


        if (this.item.id.indexOf('camp-') > -1 ) {
            this.esCampaign = true;
        }

        if (this.item.id.indexOf('off-') > -1 ) {
            this.desactivado = true;
        } else {
            this.desactivado = false;
        }


        // Subscribe to navigation item
        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        ).pipe(takeUntil(this._unsubscribeAll))
         .subscribe(() => {

             // Mark for check
             this._changeDetectorRef.markForCheck();
         });
    }

    proximamente(item: any) {
        //this.router.navigate([item.url])
        this.utils.openDialog("Función no activada", "Contacte con ventas", false, false);
    }

    campaign(item: any) {

        console.log(this.globalService.campaignActual, item)
        if (!this.globalService.campaignActual) {
            this.utils.openDialog("No hay campaña actual", "Contacte con ventas", false, false);
        } else {
            this.router.navigateByUrl(item.url);
        }
    }

    estiloNavegar(item: any) {
        if (item.id.indexOf('camp-') > -1) {
            return this.globalService.campaignActual ? true : false;
        } else {
            return true;
        }
    }

    test(item: any) {
        console.log('En test', item);
        item.url = '#'
    }

    contacto() {
        this.utils.openDialog("Email de contacto", "contact@aivoz.es", false, false);
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
