export const locale = {
    lang: 'en',
    data: {
        'SHARE_WHATSAPP': {
            'TITLE': 'Share by WhatsApp',
            'USE_CUSTOM_NUMBER': 'I don\'t have the number in my contacts',
            'CHOOSE_NUMBER': 'Choose contacts directly from WhatsApp',
            'WRITE_NUMBER': 'Write a number',
            'ADDITIONAL_INFO': '*You must include the country code',
            'PHONE_NUMBER': '+0000 000 000 000',
            'BACK': 'Back',
            'SEND': 'Send',

            
        }
    }
};
