export const locale = {
    lang: 'es',
    data: {
        'DIGITAL_FILE': {
            'MY_FILES': 'Mis archivos',
            'SEARCH': 'Buscar carpeta',
            'SIDEBAR': {
                'MAIN': {
                    'TITLE': 'ADOC',
                    'MY_FILES': 'Mis archivos',
                    'STARRED': 'Favoritos',
                    'SHARRED': 'Compartidos',
                    'RECENT': 'Recientes',
                }
            },
            'LIST': {
                'NAME': 'Nombre',
                'TYPE': 'Tipo',
                'OWNER': 'Creador',
                'SIZE': 'Tamaño',
                'MODIFIED': 'Modificado',
                'FOLDER': 'Carpeta',
                'FILE': 'Archivo',
                'ADD_FILE': 'Añadir archivo',
                'NO_FILES': 'No hay archivos. Crea uno para empezar.',
                'NO_FOLDERS': 'No hay carpetas. Crea una para empezar.',
            },
            'DETAILS': {
                'SIGN_STATUS': 'Estado de la firma',
                'OF': 'de',
                'COMPLETED': 'completado.',
                'SIGNERS': 'Firmantes',
                'EDITED': 'Editado',
                'INFO': 'Información',
                'TYPE': 'Tipo',
                'SIZE': 'Tamaño',
                'LOCATION': 'Localización',
                'OWNER': 'Creador',
                'MODIFIED': 'Modificado',
                'OPENED': 'Abierto',
                'CREATED': 'Creado',
                'SHARE': 'Compartir',
                'FOLDER': 'Carpeta',
                'FILE': 'Archivo',
                'DELETE': 'Borrar',
                'SIGN': 'Firmar',
                'PUBLIC': 'Pública',
                'PRIVATE': 'Privada',
                'PUBLIC_EXPLAIN': 'Se puede acceder a la carpeta sin código privado.',
                'PRIVATE_EXPLAIN': 'Sólo puedes acceder a la carpeta con un código privado.',
                'PRIVATE_CODE': 'Código privado',
                'SIGNED': 'Firmado',
                'DOWNLOAD': 'Descargar',
                'COPY_LINK': 'Copiar enlace',
                'SIGN_LINK': 'Copiar enlace firma',
                'COPIED_LINK': 'Copiado',
                'VIEW_CHECKLIST': 'Ver listado',
                'ENABLE_SIGN': 'Habilitar firma',
                'REACTIVATE': 'Reactivar',
                'CANCEL_SIGN': 'Cancelar',
                'ADD_CHECKLIST': 'Añadir listado',
                'CONFIRM_DELETE_FOLDER': '¿Desea borrar esta carpeta?',
                'CONFIRM_DELETE_FILE': '¿Desea borrar este documento?',
                'TEXT_CONFIRM_DELETE': 'Esta operación puede llevar varios minutos, no recargue la página en el proceso.',
                'REMOVED_FOLDER': 'La carpeta ha sido eliminada.',
                'REMOVED_FILE': 'El documento ha sido eliminado.',
                'BAD_PARAMETERS': 'La acción no se ha podido llevar a cabo. Recargue la página por favor.',
                'CLIENT_NOT_FOUND': 'La acción no se ha podido llevar a cabo. Recargue la página por favor.',
                'FOLDER_NOT_FOUND': 'La carpeta no se encuentra o ya ha sido borrada.',
                'DOCUMENT_NOT_FOUND': 'El documento no se encuentra o ya ha sido borrado.',
                'UNAVAILABLE_SERVICE': 'El servicio no está disponibles en estos momentos. Por favor vuelva a intentarlo en unos minutos. Sentimos las molestias.',
                'NO_PDF_FILE': 'El archivo debe ser PDF para habilitar la firma.',
                'PROTECTED_PDF': 'El archivo PDF está protegido o cifrado, por lo que no se puede habilitar la firma.',
            },
            'DIALOGS': {
                'FOLDER_FORM': {
                    'TITLE': "Nueva carpeta",
                    'OWNER': "Creador",
                    'NAME': "Nombre de la carpeta",
                    'CREATE': "Crear",
                    'VIEW_CHECKLIST': "Ver listados de archivos",
                    'SELECT_TO_VIEW_CHECKLIST': "Selecciona para ver el listado",
                    'SELECT': "Seleccionar",
                    'BACK': "Volver",
                    "CHECKLIST": "Listado de archivos",
                    'OTHERS': "Otros",
                    'ERRORS': {
                        'UNKNOWN': 'Servicio no disponible en estos momentos. Inténtelo de nuevo en unos minutos.',
                        'NO_ID_CLIENT': 'Es necesario que recargue para realizar esta acción.',
                        'DELETED_CURRENT_FOLDER': 'La carpeta en la que intenta crear la carpeta ha sido eliminada. Recargue la página por favor.',
                        'NAME_EXISTS': 'Ya existe otra carpeta con el mismo nombre.',
                    },
                },
                'CHECKLIST_FORM': {
                    'TITLE': "Lista predeterminada",
                    'CREATE': "Crear",
                    'VIEW_CHECKLIST': "Ver listados de archivos",
                    'SELECT_TO_VIEW_CHECKLIST': "Selecciona para ver el listado",
                    'SELECT': "Seleccionar",
                    'ADD_CHECKLIST': 'Añadir',
                    'BACK': "Volver",
                    'SAVE': "Guardar",
                    'CLOSE': "Cerrar",
                    'CANCEL': "Cancelar",
                    "CHECKLIST": "Listado de archivos",
                    'WRITE_TITLE': "Título",
                    'WRITE_DESCRIPTION': "Descripción",
                    'NO_CHECKLIST_SELECTED': 'No se ha seleccionado ningún listado de archivos para esta carpeta.',
                    'ERRORS': {
                        'UNKNOWN': 'Servicio no disponible en estos momentos. Inténtelo de nuevo en unos minutos.',
                        'NO_ID_CLIENT': 'Es necesario que recargue para realizar esta acción.',
                        'DELETED_CURRENT_CHECKLIST': 'La carpeta en la que intenta crear el listado ha sido eliminada. Recargue la página por favor.',
                    },
                },
                'FILE_FORM': {
                    'TITLE': "Nuevo documento",
                    'OWNER': "Creador",
                    'NAME': "Nombre del archivo",
                    'UPLOAD': "Subir",
                    'FILE': "Archivo",
                    'CHOOSE_FILE': "Elegir archivo",
                    'ERRORS': {
                        'UNKNOWN': 'Servicio no disponible en estos momentos. Inténtelo de nuevo en unos minutos.',
                        'NO_CLIENT_ID': 'Es necesario que recargue para realizar esta acción.',
                        'NO_FOLDER_ID': 'Es necesario que recargue para realizar esta acción.',
                        'NO_DOCUMENT_NAME': 'Es necesario escribir un nombre para el archivo.',
                        'FILE_ERROR': 'No se ha detectado correctamente el archivo. Por favor, selecciónelo de nuevo y vuelva a intentarlo.',
                        'NO_FILE': 'Es necesario especificar el archivo que desea subir.',
                        'FOLDER_NOT_FOUND': 'La carpeta, en la que intenta crear el documento, ha sido eliminada. Recargue la página por favor.',
                        'UNKNOWN_FILE_FORMAT': 'El formato del archivo no se ha reconocido, elija uno distinto por favor.',
                        'CLIENT_NOT_FOUND': 'Es necesario que recargue para realizar esta acción.',
                        'NAME_DOCUMENT_EXISTS': 'Ya existe otro documento con el mismo nombre en esta carpeta.',
                        'UNAVAILABLE_SERVICE': 'El servicio no está disponible en estos momentos. Inténtelo de nuevo en unos minutos.',
                    },
                },
                'SHARE_EMAIL': {
                    'TITLE': 'Compartir por correo electrónico',
                    'ADD_EMAIL': 'Añadir otro correo',
                    'EMAIL_INPUT': 'Escriba un correo electrónico',
                    'SHARE': 'Compartir',
                    'EMAIL_ERROR': 'El correo electrónico no es válido, por favor revisa los datos introducidos.',
                    'SHARED_SUCCESSFULLY': 'Los correos han sido enviados correctamente.',
                    'UNAVAILABLE_SERVICE': 'El servicio no está disponibles en estos momentos. Por favor vuelva a intentarlo en unos minutos. Sentimos las molestias.',
                },
                'CREATE_SIGN': {
                    'TITLE': 'Generar firmas',
                    'TITULO': 'Título',
                    'DESCRIPTION': 'Descripción (opcional)',
                    'SENDING_TYPE': 'Tipo de envío',
                    'LINK': 'Enlace individual',
                    'EMAIL': 'Correo',
                    'EMAIL_INPUT': 'Escriba un correo electrónico',
                    'LINK_NUMBER': 'Número de enlaces',
                    'SEND': 'Enviar',
                    'DOCUMENT_TYPE': 'Escoja su tipo de firma',
                    'MULTISIGNATURE':'Firma múltiple',
                    'GENERATE_LINK':'Generar enlaces',
                    'SIGNATORIES_NUMBER':'Número de firmantes',
                    'SELECT_SIGNATURIES_NUMBER': 'Selecciona el número total de firmantes',
                    'NEXT': 'Continuar',
                    'BACK': 'Atrás',
                    'SELECT_AN_OPTION': 'Seleccione una opción',
                    'FINISH': 'Finalizar'
                },
            },
        }
    }
};
