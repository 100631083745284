import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { DialogComponent } from './dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
    declarations: [
        DialogComponent
    ],
    imports     : [
        MatButtonModule,
        MatDialogModule,
        TranslateModule,

        FuseSharedModule
    ],
    exports     : [
        DialogComponent
    ]
})

export class DialogModule
{
}
