import { Injectable, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogComponent } from 'app/dialogs/dialog/dialog.component';
import { ShareIdCallComponent } from 'app/dialogs/share-idcall/share-idcall.component';
import { ShareWhatsappComponent } from 'app/dialogs/share-whatsapp/share-whatsapp.component';
import { ShareEmailComponent } from 'app/main/apps/file-manager/dialogs/share-email/share-email.component';
import { DocumentoCarpeta, Evento } from 'app/models/data.model';
import { PDFDocument } from 'pdf-lib';

@Injectable()
export class UtilityProvider {

  public SUCCESS = "success";
  public WARNING = "warning";
  public ERROR = "error";
  public INFO = "info";
  public TARJETA = "Método Pago";
  public FACTURACION = "Datos Facturación";
  public CODIGO = "Código Promocional";
  public TERMINOS = "Términos y Condiciones";

  constructor(
    private dialog: MatDialog
  ) {
  }

  /**
   * Función que permite mostrar un mensaje en pop-up.
   * 
   * @param title Texto principal, si se corresponde con "success", "warning", o "error" se personaliza con icono
   * @param message Cuerpo del mensaje
   * @param accept Si es true, se dará la opción de aceptar o cancelar.
   * @param disableClose Si es false, se podrá pulsar fuera de la ventana para cerrarla.
   * @param textAccept Si se recibe, el texto de aceptar se sobreescribirá.
   * @param data Datos adicionales si fuese necesario.
   */
  openDialog(title: string, message: string, accept: boolean, disableClose: boolean, textAccept?: string, data?: any) {


    return new Promise<any>((resolve, reject) => {
      const dialogRef = this.dialog.open(DialogComponent, {
        data: { title: title, message: message, accept: accept, textAccept: textAccept, otherData: data },
        disableClose: disableClose
      });
      dialogRef.afterClosed().subscribe(result => {
        // //console.log(`Dialog result: ${result}`);
        resolve(result);
      });
    });
  }

  /**
   * Función que permite mostrar un pop-up para escribir un teléfono y compartir por whatsapp la información.
   * 
   * @param text Texto que se compartirá.
   * @param link Enlace para compartir por web o aplicación.
   * @param disableClose Si es false, se podrá pulsar fuera de la ventana para cerrarla.
   * @param data Datos adicionales si fuese necesario.
   */
  shareWhatsapp(text: string, disableClose: boolean, data?: any) {

    return new Promise<any>((resolve, reject) => {
      const dialogRef = this.dialog.open(ShareWhatsappComponent, {
        data: { text: text, otherData: data },
        panelClass: 'share-whatsapp-dialog',
        disableClose: disableClose
      });
      dialogRef.afterClosed().subscribe(result => {
        // //console.log(`Dialog result: ${result}`);
        resolve(result);
      });
    });
  }

  /**
   * Función que permite mostrar un pop-up para escribir un teléfono y compartir por whatsapp la información.
   * 
   * @param enlaceUsuario Texto que se compartirá.
   * @param enlaceAdministrador Enlace de acceso a la videollamada para el administrador.
   * @param evento Enlace para compartir por web o aplicación.
   * @param disableClose Si es false, se podrá pulsar fuera de la ventana para cerrarla.
   * @param data Datos adicionales si fuese necesario.
   */
  shareIdCall(enlaceUsuario: string, enlaceAdministrador: string, evento: Evento, disableClose: boolean, data?: any) {

    return new Promise<any>((resolve, reject) => {
      const dialogRef = this.dialog.open(ShareIdCallComponent, {
        data: { enlaceUsuario: enlaceUsuario, enlaceAdministrador: enlaceAdministrador, evento: evento, otherData: data },
        panelClass: 'share-idcall-dialog',
        disableClose: disableClose
      });
      dialogRef.afterClosed().subscribe(result => {
        // //console.log(`Dialog result: ${result}`);
        resolve(result);
      });
    });
  }

  /**
   * Compartir carpeta por email y asociarlo para recibir notificaciones
   */
  shareEmail(asunto: string, text: string, disableClose: boolean, data?: any) {

    return new Promise<any>((resolve, reject) => {
      const dialogRef = this.dialog.open(ShareEmailComponent, {
        data: { asunto: asunto, text: text, otherData: data ? data : {} },
        panelClass: 'share-email-dialog',
        disableClose: disableClose
      });
      dialogRef.afterClosed().subscribe(result => {
        // //console.log(`Dialog result: ${result}`);
        resolve(result);
      });
    });
  }

  /**
   * Función que permite pasar de tipo Blob a File un archivo
   */
  blobToFile = (theBlob: Blob, fileName: string): File => {
    let b: any = theBlob;
    //Blob() es también File() - Añadimos los dos parámetros necesarios para un archivo tipo File
    b.lastModifiedDate = new Date();
    b.name = fileName;

    //Casteamos al tipo File()
    return <File>theBlob;
  }

  getCodigosPromocionales() {
    let codigos_promocion = [
      { codigo: '5TPS5', tarjeta: true, stripe: true },
      { codigo: '5ASO5', tarjeta: true, stripe: true },
      { codigo: '3SMART3', tarjeta: true, stripe: true },
      { codigo: '1SMART1', tarjeta: true, stripe: true },
      { codigo: 'RES20', tarjeta: true, stripe: true },
      { codigo: 'IDDEAS', tarjeta: true, stripe: true },
      { codigo: '100%SMART', tarjeta: true, stripe: false },
      { codigo: 'FAI100', tarjeta: true, stripe: false },
      { codigo: 'DESCUENTO%100', tarjeta: true, stripe: false },
      { codigo: 'INMOVILLA100', tarjeta: true, stripe: false },
      { codigo: 'SBK100', tarjeta: true, stripe: false },
      { codigo: 'COM10', tarjeta: true, stripe: false },
      { codigo: 'MV2020', tarjeta: true, stripe: false },
    ];
    return codigos_promocion;
  }

  /**
   * Convierte una fecha a string
   * 
   * @param tipo Tipo de fecha que se obtiene. Puede ser:
   * - 0: yyyy-mm-dd
   * - 1: dd-mm-yyyy
   * - 2: hh:MM
   * - 3: yyyymmddhhMMss
   * - 4: yyyy-mm-01
   * - 5: yyyy-mm-[Último día]
   * - 6: dd-mm-yyyy hh:MM horas
   * - 7: dd-mm-yyyy a las hh:MM horas
   * @param fecha Opcional, si no se recibe se coge por defecto la fecha actual.
   */
  fechaToString(tipo, fecha?) {
    let dias = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    let today: Date;
    if (fecha) {
      today = new Date(fecha);
    } else {
      today = new Date(Date.now());
    }

    let dd = today.getDate().toString();
    let mm = (today.getMonth() + 1).toString();
    let hh = today.getHours().toString();
    let nn = today.getMinutes().toString();
    let ss = today.getSeconds().toString();
    let yyyy: String;

    if (Number(hh) < 10) hh = '0' + hh;
    if (Number(nn) < 10) nn = '0' + nn;

    if (Number(mm) < 10) mm = '0' + mm;
    if (Number(dd) < 10) dd = '0' + dd;

    yyyy = today.getFullYear().toString();

    let result = '';

    if (tipo === 0) result = yyyy + '-' + mm + '-' + dd; // Fecha formato ingles
    if (tipo === 1) result = dd + '-' + mm + '-' + yyyy; // Fecha formato español
    if (tipo === 2) result = hh + ':' + nn; // hora formato HH:MM
    if (tipo === 3) result = yyyy + mm + dd + hh + nn + ss; // numero resumen
    if (tipo === 4) result = yyyy + '-' + mm + '-01';
    if (tipo === 5) result = yyyy + '-' + mm + '-' + dias[Number(mm) - 1];
    if (tipo === 6) result = dd + '-' + mm + '-' + yyyy + '  ' + hh + ':' + nn + ' horas.'; // Fecha formato español fecha + hora
    if (tipo === 7) result = dd + '-' + mm + '-' + yyyy + ' a las ' + hh + ':' + nn + ' horas.'; // Fecha formato español fecha + hora

    return result;
  }

  comprobarEmail(email: string) {
    let regex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
    return regex.test(email);
  }

  async compruebaDocumentoCarpetaValidoParaFirma(documento: DocumentoCarpeta) {

    if (documento.formato != "application/pdf") {
      return Promise.resolve({ status: 'bad', code: 1001, message: 'El archivo no es PDF' });
    }

    let pdfBytes;
    try {
      pdfBytes = await fetch(documento.evento.archivo.enlace).then(res => res.arrayBuffer());
      await PDFDocument.load(pdfBytes);
      return Promise.resolve({ status: 'ok' });
    } catch (error) {
      try {
        pdfBytes = await fetch('https://cors-anywhere.herokuapp.com/' + documento.evento.archivo.enlace).then(res => res.arrayBuffer())
        await PDFDocument.load(pdfBytes);
        return Promise.resolve({ status: 'ok' });
      } catch (error) {
        return Promise.resolve({ status: 'bad', code: 1002, message: 'El archivo está protegido o cifrado' });
      }
    }
  }

  generateRandomCode(length, excepcion) {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    if (excepcion) {
      if (excepcion == 'sin_O') {
        characters = 'ABCDEFGHIJKLMNPQRSTUVWXYZabcdefghijklmnpqrstuvwxyz123456789';
      } else if (excepcion == 'hexadecimal') {
        characters = '0123456789ABCDEF';
      } else if (excepcion == 'lecturaFacil') {
        characters = '123456789ABCDEF';
      } else if (excepcion == 'minuscula') {
        characters = 'abcdefghijklmnopqrstuvwxyz';
      } else if (excepcion == 'minuscula_sin_o') {
        characters = 'abcdefghijklmnpqrstuvwxyz';
      }
    }
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
}