import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { LoadingComponent } from './loading.component';

@NgModule({
    declarations: [
        LoadingComponent
    ],
    imports     : [

        TranslateModule,

        FuseSharedModule
    ],
    exports     : [
        LoadingComponent
    ]
})

export class LoadingModule
{
}
