export const locale = {
    lang: 'en',
    data: {
        'SHARE_IDCALL': {
            'TITLE': 'Sign by SBK Identity',
            'ADMIN_ACCESS': 'Admin access',
            'ADMIN_ACCESS_DESCRIPTION': 'You can enable and finish the signature during the video call.',
            'USER_LINK': 'Share link with other users',
            'DESCRIPTION': 'From this SBK Identity link they can view the document and sign it.',
            'COPIED_LINK': 'Copied',
            'COPY_LINK': 'Copy link',
            'BACK': 'back'

            
        }
    }
};
