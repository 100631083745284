export const locale = {
    lang: 'es',
    data: {
        'TOOLBAR': {
            'LOGOUT': 'Cerrar sesión',
            'MY_PROFILE': 'Mi perfil',
            'MY_SUBSCRIPTION': 'Mi Subscripción',
            'SUBSCRIPCION': 'Subscripciones',
            'TEXTO': 'Elija la subscripción que mejor se adapte a su negocio',
            'BUTTON': 'Subscribirse',
            'CANCELBUTTON': 'Cancelar',
            'METHOD': 'Método de Pago',
            'PLANACTUAL':'PLAN ACTUAL',
            'MES':'mes',
            'IMPUESTO':'Impuestos indirectos no incluidos',
            'LIMITE':'Límite: ',
            'RENOVACION':'Renovación: ',
            'CANCELARA': 'Se cancelará: ',
            'FIRMAS':'Firmas realizadas: ',
            'CANCELADA':'!SUSCRIPCION CANCELADA!'
        },
        'STYLE1': {
            'NEXT': 'Siguiente',
            'IVA':'€ (Iva incluido)',
            'CHANGEPAY':'Cambiar método de Pago',
            'PAYTEXT':'Por favor, confirme su Método Pago',
            'NOPAY':'No tiene asignado un método de pago. Se le redigirá a su perfil para introducir su Método de Pago',
            'FACTURACIONCHANGE':'Cambiar Datos Facturación',
            'CODE':'Si tiene algún Código Promocional, indíquelo',
            'CODEOK':'Código Promocional Aceptado',
            'CODEBAD':'Ese Código Promocional no existe',
            'TERMS':'Aceptar los Términos y Condiciones',
            'SUBSCRIBE':'Suscribirse',
            'INFERIOR':'Va a cambiar a un plan Inferior. Debe contactar con Ventas.',
            'SUPERIOR':'Va a cambiar a un plan Superior',
            'SUBSCRIBECHANGEOK':'El cambio de suscripción se ha realizado con éxito.',
            'SUBSCRIBECHANGEBAD':'Ha ocurrido un error al cambiar la suscripción del producto.',
            'SUBSCRIBEOK':'La suscripción se ha realizado con éxito'
        },
        'PROFILE': {
            'UPDATEOK':'Datos actualizados con éxito.',
            'UPDATEBAD':'Error al Actualizar Cliente',
            'UPDATEPASSWORDBAD':'La contraseña original no es correcta',
            'PAYMENT':'¿ Desea actualizar el método de Pago ?',
            'PAYMENTNODATA':'No se modificado ningún dato del método de pago' ,
            'PAYMENTBAD':'Ha ocurrido un error al actualizar su método de pago. Revise los datos.',
            'PAYMENTOK':'La método de Pago se ha actualizado con éxito',
        }
    }
};
