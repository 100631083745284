import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { ShareIdCallData } from 'app/models/others.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as spanish } from './i18n/es';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { fuseAnimations } from '@fuse/animations';
import { FormControl, FormGroup } from '@angular/forms';
import { Evento } from 'app/models/data.model';

@Component({
  selector: 'share-idcall',
  templateUrl: './share-idcall.component.html',
  styleUrls: ['./share-idcall.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class ShareIdCallComponent implements OnInit {

  compartir: boolean;
  idcallForm: FormGroup;
  anulaApertura: boolean;

  evento: Evento;
  enlaceUsuario: string;
  enlaceAdministrador: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ShareIdCallData,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    public dialogRef: MatDialogRef<FuseConfirmDialogComponent>
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, spanish);
    // Set the defaults
    this.idcallForm = this.createIdCallForm();
  }

  ngOnInit(): void {
    this.compartir = false;
    this.evento = this.data.evento;
    this.enlaceAdministrador = this.data.enlaceAdministrador;
    this.enlaceUsuario = this.data.enlaceUsuario;
  }

  /**
   * Create send form
   *
   * @returns {FormGroup}
   */
  createIdCallForm(): FormGroup {
    return new FormGroup({
      phoneNumber: new FormControl(''),
      conNumero: new FormControl(false),
    });
  }

  compartirEnlace() {
    this.compartir = true;
  }

  volver() {
    this.compartir = false;
  }

  abrirComoAdmin() {
    window.open(this.enlaceAdministrador, 'blank');
    this.dialogRef.close(true);
  }

  copiarEnlace() {
    this.anulaApertura = true;
    setTimeout(() => {
      this.anulaApertura = false;
    }, 200);
    this.copyTextToClipboard(this.enlaceUsuario);
    this.evento.copiado = true;
    this.evento.cuentaNumeros = 0;
    clearInterval(this.evento.compruebaCuenta);
    this.evento.compruebaCuenta = setInterval(() => {
      this.evento.cuentaNumeros++;
      if (this.evento.cuentaNumeros > 4) {
        clearInterval(this.evento.compruebaCuenta);
        this.evento.copiado = false;
      }
    }, 1000);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ FUNCIONES AUXILIARES
  // -----------------------------------------------------------------------------------------------------

  mobileAndTabletCheck() {

    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
    ];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }

  copyTextToClipboard(text) {
    let txtArea = document.createElement("textarea");
    txtArea.id = 'txtcopy';
    txtArea.style.position = 'absolute';
    txtArea.style.top = '0';
    txtArea.style.left = '0';
    txtArea.style.opacity = '0';
    txtArea.value = text;
    document.body.appendChild(txtArea);
    txtArea.focus();
    txtArea.select();

    try {
      let successful = document.execCommand('copy');
      if (successful) {
        return true;
      }
    } catch (error) {
      //console.log('No se ha podido copiar el texto: ', error);
      return false;
    } finally {
      document.body.removeChild(txtArea);
    }
    return false;
  }
}
