<!-- <div class="dialog-content-wrapper">
    <div>
        <mat-toolbar class="mat-accent m-0">
            <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
                <span class="title dialog-title">
                    {{'SHARE_IDCALL.TITLE' | translate}}
                    <img class="image" src="../../../../../../assets/icons/social-networks/whatsapp.png" />
                </span>
                <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-toolbar-row>
        </mat-toolbar>
    </div>

    <div mat-dialog-content class="m-0 {{loading ? 'cero-padding' : 'mat-padding'}}" fusePerfectScrollbar>

        <h1 class="title_center" mat-dialog-title>
                    {{'SHARE_IDCALL.TITLE' | translate}}
                    <img class="image" src="../../../../../../assets/icons/social-networks/whatsapp.png" />
            </h1>
        <div class="message" mat-dialog-content layout="row" layout-align="center center" flex>
            <p style="text-align: justify;">
                <strong>{{'SHARE_IDCALL.WRITE_NUMBER' | translate}}</strong><br />
                <em>{{ 'SHARE_IDCALL.ADDITIONAL_INFO' | translate }}</em>
            </p>
        </div>

        <div class="message" mat-dialog-content layout="row" layout-align="center center" flex>
            <input type="text" class="minput" placeholder="{{ 'SHARE_IDCALL.PHONE_NUMBER' | translate }}"
                [(ngModel)]="phoneNumber">
        </div>

        <br>

        <div class="buttons">

            <button mat-button (click)="dialogRef.close(false)">{{'SHARE_IDCALL.BACK' | translate}}</button>

            <button mat-raised-button class="mat-accent"
                (click)="enviarMensaje()">{{'SHARE_IDCALL.SEND' | translate}}</button>

        </div>
    </div>
</div> -->

<div class="dialog-content-wrapper">
    <div>
        <mat-toolbar class="mat-accent m-0">
            <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
                <span class="title dialog-title">
                    <!-- <img class="image" src="../../../../../../assets/icons/social-networks/whatsapp.png" /> -->
                    <mat-icon>videocam</mat-icon>
                    {{'SHARE_IDCALL.TITLE' | translate}}
                </span>
                <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-toolbar-row>
        </mat-toolbar>
    </div>

    <div mat-dialog-content class="m-0 mat-padding" fusePerfectScrollbar>

        <!-- El formulario dispondrá de diversos pasos, cada uno se mostrará de manera independiente -->
        <form name="idcallForm" [formGroup]="idcallForm" class="idcall-form" fxLayout="column" fxFlex>

            <div class="interior {{ compartir ? 'oculto' : 'muestra'}}">

                <!-- CONTENT CARD -->
                <mat-card class="tipoFirma" (click)="abrirComoAdmin()">
                    <mat-card-header>
                        <mat-card-title class="centrar-icono">
                            <mat-icon>verified_user</mat-icon>&nbsp;{{'SHARE_IDCALL.ADMIN_ACCESS' | translate}}
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <p>
                            {{'SHARE_IDCALL.ADMIN_ACCESS_DESCRIPTION' | translate}}
                        </p>
                    </mat-card-content>
                </mat-card>
                <br />
                <mat-card class="tipoFirma" (click)="compartirEnlace()">
                    <mat-card-header>
                        <mat-card-title class="centrar-icono">
                            <mat-icon>account_box</mat-icon>&nbsp;{{'SHARE_IDCALL.USER_LINK' | translate}}
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <p>
                            {{'SHARE_IDCALL.DESCRIPTION' | translate}}
                        </p>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="compartirEnlace {{ compartir ? 'muestra' : 'oculto'}}">

                <qr-code [value]="enlaceUsuario" [size]="180"></qr-code>
                <br />

                <button mat-raised-button color="basic" (click)="copiarEnlace()" class="copy-button"
                    aria-label="COPY">
                    <img class="copy-image" src="../../../../../../assets/icons/material-icons/copy.png" />
                    &nbsp;
                    {{ evento.copiado ? ('SHARE_IDCALL.COPIED_LINK' | translate) : ('SHARE_IDCALL.COPY_LINK' | translate) }}
                </button>
            </div>
        </form>

    </div>

    <div mat-dialog-actions class="m-0 p-16 {{ compartir ? 'muestra' : 'oculto'}}" fxLayout="row"
        fxLayoutAlign="center center">
        <div>
            <button mat-raised-button color="accent" (click)="volver()" class="save-button">
                {{ 'SHARE_IDCALL.BACK' | translate }}
            </button>
        </div>
    </div>
</div>