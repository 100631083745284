import { Injectable } from '@angular/core';
import { RestWS } from './restService';
import { Credenciales } from '../models/form.model';
import { Usuario, Plantilla, Evento, Cliente, Carpeta, DocumentoCarpeta, PuntoCheckList } from '../models/data.model';
import { CryptProvider } from 'app/providers/crypt/crypt';
import { GlobalService } from './globalService';
import { PDFDocument } from 'pdf-lib';

@Injectable()
export class DataManagement {
  callActual: import("/Users/developer/Desktop/angular/SmartBiometrikDash/src/app/models/others.model").Calls[];
  constructor(
    private restService: RestWS,
    private crypto: CryptProvider
  ) { }

  socket: any;

  // ======================== AIVOZ ========================

  public login(credenciales: Credenciales): Promise<any> {
    return this.restService
      .login(credenciales)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      });
  }

  public decode(texto: string): Promise<any> {
    return this.restService
      .decode(texto)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      });
  }

  public getCampaigns(cliente): Promise<any> {
    return this.restService
      .getCampaigns(cliente)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      });
  }

  public getCalls(cliente, campaign, ini, fin): Promise<any> {
    return this.restService
      .getCalls(cliente, campaign, ini, fin)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      });
  }

  public getRecording(cliente, campaign, call): Promise<any> {
    return this.restService
      .getRecording(cliente, campaign, call)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      });
  }

  public makeVoice(locutor, text): Promise<any> {
    return this.restService
      .makeVoice(locutor, text)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      });
  }

  // ======================== Cliente ========================

  public getClient(idCliente: string): Promise<any> {
    return this.restService
      .getClient(idCliente)
      .then(data => {
        if (data.status == 'ok') {
          if (data.cliente.tarjetaPago) {
            data.cliente.tarjetaPago = this.crypto.decryptData(data.cliente.tarjetaPago, 'smart2020pay');
          }
        }
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      })
  }

  public getClientByEmail(email: string): Promise<any> {
    return this.restService
      .getClientByEmail(email)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      })
  }

  /**
   * Función temporal para editar y activar clientes de manera automática
   * @param cliente 
   */
  public registrarCliente(cliente: Cliente): Promise<any> {
    return this.restService
      .registrarCliente(cliente)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      })
  }

  public createClienteFace(cliente): Promise<any> {
    return this.restService
      .createClienteFace(cliente)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      })
  }

  public getClienteFacebyEmail(email): Promise<any> {
    return this.restService
      .getClienteFaceByEmail(email)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      })
  }

  public editarCliente_temporal(cliente: Cliente): Promise<any> {
    return this.restService
      .editarCliente_temporal(cliente)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      })
  }

  public editarCliente(cliente: Cliente, admin: boolean, campos?: any): Promise<any> {
    return this.restService
      .editarCliente(cliente, admin, campos)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      })
  }

  // ======================== ARCHIVOS Y CORREO ELECTRÓNICO ========================

  /**
   * Subir archivos a servidor
   * @param body Parámetros de la subida (FormData) con selectFile, path y remotename.
   */
  public subirArchivo(body): Promise<any> {
    return this.restService
      .subirArchivo(body)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      });
  }

  /**
   * Borrar archivo del servidor
   * @param archivo Parámetros del borrado que se trata de un string compuesto del enlace, nombre y extión del archivo.
   */
  public borrarArchivo(formData): Promise<any> {
    return this.restService
      .borrarArchivo(formData)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      });
  }

  /**
    * Enviar correo electrónico desde servidor, se envía desde la dirección "noreply@smartbiometrik.com".
    * @param asunto 
    * @param mensaje 
    * @param mailTo 
    * @param cc Opcional
    * @param cco Opcional
    * @param firma Opcional
    */
  public sendGenericMail(asunto, mensaje, mailTo, cc?, cco?, firma?: boolean): Promise<any> {
    return this.restService
      .sendGenericMail(asunto, mensaje, mailTo, cc, cco, firma)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      });
  }

  /**
    * Enviar correo electrónico desde servidor, se envía desde la dirección "noreply@smartbiometrik.com".
    * @param asunto 
    * @param mensaje 
    * @param mailTo 
    * @param link Url del archivo que se va a adjuntar.
    * @param cc Opcional
    * @param cco Opcional
    * @param firma Opcional
    */
  public sendGenericMailWithAttachment(asunto, mensaje, mailTo, link, cc?, cco?, firma?: boolean): Promise<any> {
    return this.restService
      .sendGenericMailWithAttachment(asunto, mensaje, mailTo, link, cc, cco, firma)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      });
  }

  /**
    * Enviar correo electrónico desde servidor, se envía desde la dirección "noreply@smartbiometrik.com".
    * @param asunto 
    * @param mensaje 
    * @param correos 
    * @param firma Opcional
    */
  public shareTextByEmail(asunto: string, mensaje: string, correos, firma?: boolean): Promise<any> {
    return this.restService
      .shareTextByEmail(asunto, mensaje, correos, firma)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      });
  }

  /**
   * Función que actualiza un archivo en la BD
   * @param archivo Id del archivo.
   * @param enlace Enlace del archivo.
   * @param nombre Nuevo nombre que se utilizará en el archivo.
   */
  public actualizarArchivo(archivo, enlace, nombre): Promise<any> {
    return this.restService
      .actualizarArchivo(archivo, enlace, nombre)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      });
  }

  // ======================== MICROSOFT ========================

  public detectionMicrosoft(url): Promise<any> {
    return this.restService
      .detectionMicrosoft(url)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      });
  }

  public verificationMicrosoft(faceId1, faceId2): Promise<any> {
    return this.restService
      .verificationMicrosoft(faceId1, faceId2)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      });
  }

  // ======================== USUARIOS ========================

  public crearNuevoUsuario(usuario: Usuario): Promise<any> {
    return this.restService
      .crearNuevoUsuario(usuario)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      });
  }

  public editarUsuario(usuario: Usuario): Promise<any> {
    return this.restService
      .editarUsuario(usuario)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      });
  }

  // ======================== PLANTILLAS ========================

  /**
   * Función para la creación de plantillas
   * @param plantilla Plantilla que se enviará para su creación. Contiene el idCliente.    
   * Devuelve un "code" en función del resultado.    
   * @returns code: 0 --> Todo bien.    
   * @returns code: 1 --> Límite de plantillas.    
   * @returns status: bad --> Otro fallo.
   */
  public crearNuevaPlantilla(plantilla: Plantilla): Promise<any> {
    return this.restService
      .crearNuevaPlantilla(plantilla)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      });
  }

  /**
   * Función para obtener todos los datos de una plantilla
   * @param idPlantilla 
   */
  public obtenerPlantilla(idPlantilla: string): Promise<any> {
    return this.restService
      .obtenerPlantilla(idPlantilla)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      });
  }

  public borrarPlantilla(idPlantilla, idCliente): Promise<any> {
    return this.restService
      .borrarPlantilla(idPlantilla, idCliente)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      });
  }

  // ======================== EVENTOS Y ENLACES ========================

  public crearNuevoEvento(evento: Evento, idPlantilla: string, idEmisor: string, file: File): Promise<any> {
    return this.restService
      .crearNuevoEvento(evento, idPlantilla, idEmisor, file)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      });
  }

  public autoFirma(idEvento: string, file: File): Promise<any> {
    return this.restService
      .autoFirma(idEvento, file)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      });
  }

  public editarEvento(evento: Evento, campos?: any): Promise<any> {
    return this.restService
      .editarEvento(evento, campos)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      });
  }

  public editarEnlace(idEnlace: string, idEvento: string, estado: string, idUsuario?: string): Promise<any> {
    return this.restService
      .editarEnlace(idEnlace, idEvento, estado, idUsuario)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      });
  }

  public obtenerEventoDeEnlace(idEnlace: string): Promise<any> {
    return this.restService
      .obtenerEventoDeEnlace(idEnlace)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      });
  }

  public createLink(email: string): Promise<any> {
    return this.restService
      .crearLink(email)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      });
  }

  public eraseLink(link, clave): Promise<any> {
    return this.restService
      .eraseLink(link, clave)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      });
  }

  public cancelarEvento(idEvento, idCliente): Promise<any> {
    return this.restService
      .cancelarEvento(idEvento, idCliente)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      });
  }

  public finalizarEvento(idEvento, archivo, enlace, nombre): Promise<any> {
    return this.restService
      .finalizarEvento(idEvento, archivo, enlace, nombre)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      });
  }

  public verificarDocumentoDeEvento(identificador: string, archivo: File): Promise<any> {
    return this.restService
      .verificarDocumentoDeEvento(identificador, archivo)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      });
  }

  // ======================== STRIPE CONNECT ========================

  /**
   * Función para la creación de cuenta de Stripe Connect, que se asigna automáticamente al cliente seleccionado.
   * 
   * @param cliente Cliente al que se le asociará la nueva cuenta Connect
   * @param pais País del cliente que utilizará la cuenta. Deben ser dos letras en mayúscula. Ej: Para España sería 'ES'
   */
  public crearNuevaCuentaStripeConnect(cliente: Cliente, pais: string): Promise<any> {
    return this.restService
      .crearNuevaCuentaStripeConnect(cliente, pais)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      });
  }

  /**
   * Función para obtener la cuenta de Stripe Connect asignada al cliente.
   * 
   * @param idStripeConnect Identificador único de la cuenta, creada por el cliente, de Stripe Connect
   */
  public obtenerCuentaStripeConnect(idStripeConnect: string): Promise<any> {
    return this.restService
      .obtenerCuentaStripeConnect(idStripeConnect)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      });
  }

  // ======================== ARCHIVO DIGITAL ========================

  /**
   * Función para obtener todas las carpetas asociadas a un cliente.
   * 
   * @param idCliente Identificador único del cliente
   */
  public obtenerCarpetasCliente(idCliente: string): Promise<any> {
    return this.restService
      .obtenerCarpetasCliente(idCliente)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      });
  }

  /**
   * Función para obtener una carpeta específica.
   * 
   * @param idCarpeta Identificador único de la carpeta
   */
  public obtenerCarpetaPorId(idCarpeta: string): Promise<any> {
    return this.restService
      .obtenerCarpetaPorId(idCarpeta)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      });
  }

  /**
   * Función para la creación de carpetas en cliente
   * 
   * @param idCliente 
   * @param idCarpetaPadre 
   * @param nombre 
   * @param creador 
   * @param idCheckList 
   */
  public crearNuevaCarpeta(idCliente: string, idCarpetaPadre: string, nombre: string, creador: string, idCheckList: string): Promise<any> {
    return this.restService
      .crearNuevaCarpeta(idCliente, idCarpetaPadre, nombre, creador, idCheckList)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      });
  }

  /**
   * Función para la edición de carpetas en cliente
   * 
   * @param idCliente 
   * @param idCarpeta 
   * @param datos Puede contener: 
   * - nombre
   * - compartido
   * - favorito
   * - anclado
   * - fechaApertura
   * - borrado
   * - idCarpetaPadre
   * - actionCheckList
   * - tituloCheckList
   * - descripcionCheckList
   * - idPuntoCheckList
   * - tituloPuntoCheckList
   * - iconoPuntoCheckList
   * - opcionalPuntoCheckList
   * - completadoPuntoCheckList
   * - idCheckList
   */
  public editarCarpeta(idCliente: string, idCarpeta: string, datos: any): Promise<any> {
    return this.restService
      .editarCarpeta(idCliente, idCarpeta, datos)
      .then(data => {
        if (data.status === 'ok') {
          this.editSharedElement(data.carpeta, idCliente);
        }
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      });
  }

  /**
   * Función para la eliminación de carpetas en cliente
   * 
   * @param idCliente 
   * @param idCarpeta 
   */
  public eliminarCarpeta(idCliente: string, idCarpeta: string): Promise<any> {
    return this.restService
      .eliminarCarpeta(idCliente, idCarpeta)
      .then(data => {
        if (data.status === 'ok') {
          this.removeSharedElement(idCarpeta);
        }
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      });
  }

  /**
   * Función para obtener todos los documentos asociados a una carpeta.
   * 
   * @param idCarpeta Identificador único de la carpeta
   */
  public obtenerDocumentosCarpeta(idCarpeta: string): Promise<any> {
    return this.restService
      .obtenerDocumentosCarpeta(idCarpeta)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      });
  }

  /**
   * Función para obtener un documento específico.
   * 
   * @param idDocumento Identificador único del documento
   */
  public obtenerDocumentoPorId(idDocumento: string): Promise<any> {
    return this.restService
      .obtenerDocumentoPorId(idDocumento)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      });
  }

  /**
   * Función para la creación de documento en carpeta
   * 
   * @param idCliente 
   * @param idCarpeta 
   * @param nombre 
   * @param creador 
   * @param file Fichero
   * @param tipo Opcional
   */
  public crearNuevoDocumento(idCliente: string, idCarpeta: string, nombre: string, creador: string, file: File, tipo?: string, idPunto?: string): Promise<any> {
    return this.restService
      .crearNuevoDocumento(idCliente, idCarpeta, nombre, creador, file, tipo, idPunto)
      .then(data => {
        if (data.status === 'ok') {
          this.newElement(idCarpeta, data.documento);
        }
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      });
  }

  /**
   * Función para la edición del documento
   * 
   * @param idCarpeta 
   * @param documento 
   * @param numeroFirmantes 
   * @param firmaCliente 
   * @param tipoFirma 
   * @param firmarDocumento 
   */
  public async editarDocumento(idCarpeta: string, documento: DocumentoCarpeta, numeroFirmantes: number, firmaCliente: boolean, tipoFirma: string, firmarDocumento: string): Promise<any> {

    if (documento.formato == "application/pdf") {
      let pdfBytes;
      try {
        pdfBytes = await fetch(documento.evento.archivo.enlace).then(res => res.arrayBuffer());
        await PDFDocument.load(pdfBytes);
      } catch (error) {
        try {
          pdfBytes = await fetch('https://cors-anywhere.herokuapp.com/' + documento.evento.archivo.enlace).then(res => res.arrayBuffer())
          await PDFDocument.load(pdfBytes);
        } catch (error) {
          return Promise.resolve({ status: 'bad', code: 1002, message: 'El archivo está protegido o cifrado' })
        }
      }
    } else {
      return Promise.resolve({ status: 'bad', code: 1001, message: 'El archivo no es PDF' })
    }

    let numeroFirmantesString = numeroFirmantes != null ? numeroFirmantes.toString() : null;
    let firmaClienteString = firmaCliente != null ? firmaCliente.toString() : null;

    return this.restService
      .editarDocumento(documento._id, numeroFirmantesString, firmaClienteString, tipoFirma, firmarDocumento)
      .then(data => {
        if (data.status === 'ok') {
          this.editElement(idCarpeta, documento);
        }
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      });
  }

  /**
   * Función para la eliminación de documento
   * 
   * @param idCliente 
   * @param idDocumento 
   */
  public eliminarDocumento(idCliente: string, idDocumento: string, idCarpetaAbierta: string): Promise<any> {
    return this.restService
      .eliminarDocumento(idCliente, idDocumento)
      .then(data => {
        if (data.status === 'ok') {
          this.removeElement(idCarpetaAbierta, idDocumento);
        }
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      });
  }

  /**
   * Función para obtener todas las checkList predefinidas.
   * 
   */
  public obtenerCheckListsPredefinidas(): Promise<any> {
    return this.restService
      .obtenerCheckListsPredefinidas()
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      });
  }

  /**
   * Función para obtener todas las checkList predefinidas y personalizadas del cliente según su preferencia por ocultar las predefinidas.
   * 
   * @param idCliente 
   */
  public obtenerCheckListsPredefinidasPorCliente(idCliente: string): Promise<any> {
    return this.restService
      .obtenerCheckListsPredefinidasPorCliente(idCliente)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      });
  }

  /**
   * Función para obtener una checkList específica.
   * 
   * @param idCheckList Identificador único de la checkList
   */
  public obtenerCheckListPorId(idCheckList: string): Promise<any> {
    return this.restService
      .obtenerCheckListPorId(idCheckList)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      });
  }

  /**
   * Función para la creación de checkList predefinida
   * 
   * @param idCliente 
   * @param idCarpeta 
   * @param nombre 
   * @param creador 
   * @param file Fichero
   * @param tipo Opcional
   */
  public crearNuevaCheckListPredefinida(titulo: string, descripcion: string, predefinida: string, puntos: PuntoCheckList[]): Promise<any> {
    return this.restService
      .crearNuevaCheckListPredefinida(titulo, descripcion, predefinida, puntos)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      });
  }

  /**
   * Función para la eliminación de una checkList
   * 
   * @param idCheckList 
   */
  public eliminarCheckList(idCheckList: string): Promise<any> {
    return this.restService
      .eliminarCheckList(idCheckList)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      });
  }

  /**
   * Función para compartir una carpeta a través de correo electrónico.
   * 
   * @param idCliente Identificador único del cliente
   * @param idCarpeta Identificador único de la carpeta
   * @param correos Lista de correos con los que se va a compartir el enlace de acceso a la carpeta
   */
  public compartirCarpetaPorEmail(idCliente: string, idCarpeta: string, correos: string[]): Promise<any> {
    return this.restService
      .compartirCarpetaPorEmail(idCliente, idCarpeta, correos)
      .then(data => {
        if (data.status === 'ok') {
          this.editSharedElement(data.carpeta, idCliente);
        }
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      });
  }

  // ======================== SINPA ========================

  public crearNuevoPago(idCliente: string, concepto: string, nota: string, precio: number): Promise<any> {
    return this.restService
      .crearNuevoPago(idCliente, concepto, nota, precio)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      });
  }

  public cancelarPagoStripe(idEvento: string): Promise<any> {
    return this.restService
      .cancelarPagoStripe(idEvento)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      });
  }

  // =================== FUNCIONES PARA INTEGRACIÓN ===================

  /**
   * Función para obtener los datos de un proveedor a partir de su token
   * 
   * @param tokenProveedor 
   * @param tokenCliente 
   */
  public autoLoginClient(tokenProveedor: string, tokenCliente: string): Promise<any> {
    return this.restService
      .autoLoginClient(tokenProveedor, tokenCliente)
      .then(data => {
        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject('error: ' + error);
      })
  }

  // Funciones para SOCKET
  getElements(idCarpeta) {
    this.socket.emit('getElements', idCarpeta);
  }
  newElement(idCarpeta: string, documento: DocumentoCarpeta) {
    this.socket.emit('addElement', { idCarpeta: idCarpeta, documento: documento });
  }

  editElement(idCarpeta: string, documento: DocumentoCarpeta) {
    this.socket.emit('editElement', { idCarpeta: idCarpeta, documento: documento });
  }

  removeElement(idCarpeta: string, idElemento: string) {
    this.socket.emit('removeElement', { idCarpeta: idCarpeta, idElemento: idElemento });
  }

  editSharedElement(carpeta: Carpeta, idCliente: string) {
    this.socket.emit('editSharedElement', { idCarpeta: carpeta._id, elementoCompartido: carpeta, idCliente: idCliente });
  }

  removeSharedElement(idCarpeta: string) {
    this.socket.emit('removeSharedElement', { idCarpeta: idCarpeta });
  }
}