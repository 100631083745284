import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { ShareWhatsappData } from 'app/models/others.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as spanish } from './i18n/es';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { fuseAnimations } from '@fuse/animations';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'share-whatsapp',
  templateUrl: './share-whatsapp.component.html',
  styleUrls: ['./share-whatsapp.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class ShareWhatsappComponent implements OnInit {

  conNumero: boolean;
  phoneNumber: string;

  whatsappForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ShareWhatsappData,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    public dialogRef: MatDialogRef<FuseConfirmDialogComponent>
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, spanish);
    // Set the defaults
    this.whatsappForm = this.createWhatsappForm();
  }

  ngOnInit(): void {
    this.phoneNumber = '';
    this.conNumero = false;
  }

  /**
   * Create send form
   *
   * @returns {FormGroup}
   */
  createWhatsappForm(): FormGroup {
    return new FormGroup({
      phoneNumber: new FormControl(''),
      conNumero: new FormControl(false),
    });
  }

  enviarMensaje() {
    // window.open(this.data.link + this.phoneNumber + "&text=" + this.data.text, 'blank');
    let link = "";
    if (this.mobileAndTabletCheck()) {
      link = "https://api.whatsapp.com/send?text="+this.data.text;
    } else {
      link = "https://web.whatsapp.com/send?text="+this.data.text;
    }
    if (this.conNumero) {
      link = link + "&phone=" + this.phoneNumber;
    }
    window.open(link, 'blank');
    this.dialogRef.close(true);
  }

  eventHandler(event) {
    if (event.keyCode == 13) {
      this.enviarMensaje();
    }
  }
  
  // -----------------------------------------------------------------------------------------------------
  // @ FUNCIONES AUXILIARES
  // -----------------------------------------------------------------------------------------------------

  mobileAndTabletCheck() {

    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
    ];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }
}
