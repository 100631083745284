import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigService } from '../../config/configService';
import { AbstractWS } from './abstractService';
import { Injectable } from '@angular/core';
import { Credenciales } from '../models/form.model';
import { DatosDocumento } from '../models/others.model';
import { Usuario, Plantilla, Cliente, Evento, PuntoCheckList } from '../models/data.model';
import { CryptProvider } from 'app/providers/crypt/crypt';

@Injectable()
export class RestWS extends AbstractWS {
 
  pathSmartBiometrik = '';
  pathFace = '';
  pathFace2 = '';
  loading: boolean;
  urlApiAivoz: string = 'https://api.aivoz.es/api/aivoz/';  //'http://localhost:14500/api/aivoz/'; //  'https://api.aivoz.es/api/aivoz/';
  urlApi: string = "https://api.aivoz.es/api/"; //  'http://localhost:14500/api/' //
  constructor(
    private config: ConfigService,
    http: HttpClient,
    private crypto: CryptProvider
  ) {
    super(http);
    this.pathSmartBiometrik = this.config.configSmart().restUrlPrefix;
    this.pathFace = 'https://apicertificado.smartbiometrik.com/';
    this.pathFace2 = 'https://faceapi.smartbiometrik.com/';
    
  }
  // // 'http://localhost:9900/'
  // FACEAPI

  isface(file) {

    let url = this.pathFace2 + 'isFace'
    let body = {
      file: file
    }
    let fd = new FormData()
    fd.append('file', file);

    return this.makePostRequest(url, fd)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  checkface(file) {

    let url = this.pathFace2 + 'checkFaceToken'
    let body = {
      file: file
    }
    let fd = new FormData()
    fd.append('file', file),
      fd.append('token', 'Smart');

    return this.makePostRequest(url, fd)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  newface(file, cliente) {

    let url = this.pathFace2 + 'facesToken'

    let fd = new FormData()
    fd.append('file', file),
      fd.append('data', cliente)
    fd.append('token', 'Smart');

    return this.makePostRequest(url, fd)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  deletefaces(cliente) {

    let url = this.pathFace + 'faces/deleteFaces'

    let fd = new FormData()
    fd.append('data', cliente)
    fd.append('token', 'Smart');

    return this.makePostRequest(url, fd)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  getFaceById(data) {

    let url = this.pathFace + 'faces/getFace'

    let fd = new FormData()
    fd.append('data', data)
    fd.append('token', 'Smart');

    return this.makePostRequest(url, fd)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }


  comprobarUsuario(usuario) {
    let body = {
      cliente: usuario
    }

    let url = this.pathFace + 'getCliente'

    return this.makePostRequest(url, body)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });

  }

  sendMail(body) {

    let url = this.urlApiAivoz + 'sendMail'

    return this.makePostRequest(url, body)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });

  }

  // CAMPAÑAS

  createCampaign(body) {
    let url = this.urlApiAivoz + 'campaign/create'
    return this.makePostRequest(url, body)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });

  }

  getAivozCampaignByCampaignId(campaign) {
    let url = this.urlApiAivoz + 'campaign/getByCampaignId'
    let body = {
      campaignId: campaign
    }
    return this.makePostRequest(url, body)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });

  }

  updateAivozCampaign(body) {
    let url = this.urlApiAivoz + 'campaign/update'
    return this.makePostRequest(url, body)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });

  }

  // EMPRESAS

  createEmpresa(body) {
    

    let url = this.urlApiAivoz + 'empresa/create'

    return this.makePostRequest(url, body)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });

  }

  updateEmpresa(body) {

    let url = this.urlApiAivoz + 'empresa/update'

    return this.makePostRequest(url, body)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });

  }

  getEmpresas() {
    
    let url = this.urlApiAivoz + 'empresa/getAll'

    return this.makePostRequest(url, null)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });

  }

  getEmpresaByEmail(email) {
    
    let body = {
      email: email
    }

    let url = this.urlApiAivoz + 'empresa/getByEmail'

    return this.makePostRequest(url, body)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });

  }

  ///

  public getEvent(event) {

    return this.makeGetRequest(this.pathSmartBiometrik + 'sb/event/' + event, '')
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  // AIVOZ UTIL




  public makeCall(body: any) {
    console.log('call: ', body);
     return this.makePostRequest(this.urlApiAivoz + 'makeCall', body)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  public getData() {
    
    return this.makePostRequest(this.urlApi + 'util/getData', null)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  // AIVOZ FUNCTIONS

  public makeVoice(locutor, text) {
    let body = {
      locutor: locutor,
      text: text
    }
    return this.makePostRequest(this.urlApi + 'eleven/voice', body)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  public login(credenciales: Credenciales) {
    let body = {
      user: credenciales.email,
      pass: credenciales.clave
    }
    return this.makePostRequest(this.urlApiAivoz + 'login', body)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  public getCampaigns(cliente:any) {
    this.loading = true; 
   
    return  this.makePostRequest(this.urlApiAivoz + 'getCampaigns', cliente)
        .then(res => {
          return Promise.resolve(res);
        })
        .catch(error => {
          //console.log('Error: ' + error);
          return Promise.reject(error);
        });
   
  }

  public getAivozCallsByCampaign(campaign:any, ini: any, fin: any) {
    this.loading = true; 
    let body = { 
      idCampaign: campaign,
      ini: ini,
      fin: fin
    }
    return  this.makePostRequest(this.urlApiAivoz + 'call/getByCampaignId', body)
        .then(res => {
          return Promise.resolve(res);
        })
        .catch(error => {
          //console.log('Error: ' + error);
          return Promise.reject(error);
        });
   
  }

 

  public decode(texto:any) {
    this.loading = true;
    let body = {
      texto: texto
    }

   
    return  this.makePostRequest(this.urlApi + 'eleven/decode', body)
        .then(res => {
          return Promise.resolve(res);
        })
        .catch(error => {
          //console.log('Error: ' + error);
          return Promise.reject(error);
        });
   
  }

  public getCalls(cliente:any, campaign: any, ini: string, fin: string) {
    this.loading = true; 
    let body = {
      campaign: campaign,
      aivozData: cliente,
      ini: ini,
      fin: fin
    }
    return  this.makePostRequest(this.urlApiAivoz + 'getCalls', body)
        .then(res => {
          return Promise.resolve(res);
        })
        .catch(error => {
          //console.log('Error: ' + error);
          return Promise.reject(error);
        });
   
  }
  
  getRecording(cliente: any, campaign: any, call: any) {
    this.loading = true; 
    let body = {
      campaign: campaign,
      aivozData: cliente,
      call: call
    }
    return this.makePostRequest(this.urlApiAivoz + 'getRecording', body)
        .then(res => {
          return Promise.resolve(res);
        })
        .catch(error => {
          //console.log('Error: ' + error);
          return Promise.reject(error);
        });
   
  }

  
  getUrlRecording(recording: any) {
      let body = new FormData();
     
      body.append('folder', recording.folder);
      body.append('ip', recording.ip); 
      body.append('format', recording.format);
      body.append('record_name', recording.record_name); 
      body.append('server_pw', recording.server_pw);
      body.append('server_user', recording.server_user);
  
      return this.makePostRequest('https://control.easychatbot.net/recordings_scp.php', body)
      .then(res => {
        console.log(res)
       let url = 'https://control.easychatbot.net/callmaster-rec/'+recording.record_name+'.wav';
        return Promise.resolve(url);
      })
      .catch(error => {
        console.log('Error: ' + error);
        return Promise.reject(error);
      });
   
  }

  public getTranscription(cliente:any, call: any) {
    let body = {
      aivozData: cliente, 
      call: call
    }   
    return  this.makePostRequest(this.urlApiAivoz + 'getTranscription', body)
        .then(res => {
          return Promise.resolve(res);
        })
        .catch(error => {
          //console.log('Error: ' + error);
          return Promise.reject(error);
        });
   
  }
  

  public login_old(credenciales: Credenciales) {
    const fd = new HttpParams()
      .set('email', credenciales.email)
      .set('clave', credenciales.clave);
    return this.makePostRequest(this.pathSmartBiometrik + 'sb/login/company', fd)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }



  public createClienteFace(cliente) {

    return this.makePostRequest(this.pathFace + 'createCliente', cliente)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });

  }

  public updateClienteFace(cliente, id, clave) {

    let body = {
      nombre: cliente.razonSocial,
      organizacion: cliente.cif,
      pass: clave,
      idSmart: cliente._id,
      _id: id
    }


    return this.makePostRequest(this.pathFace + 'updateCliente', body)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });

  }

  public getClienteFaceByEmail(email) {
    console.log('Getclientebyemail: ', email);
    let body = {
      email: email
    }
    return this.makePostRequest(this.pathFace + 'getClienteByEmail', body)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });

  }

  public getClient(idCliente: string) {
    const fd = new HttpParams();
    return this.makeGetRequest(this.pathSmartBiometrik + 'sb/clients/' + idCliente, fd)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  public getClientByEmail(email: string) {
    const fd = new HttpParams()
      .set('email', email);
    return this.makePostRequest(this.pathSmartBiometrik + 'sb/clientbyEmail/', fd)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  public registrarCliente(cliente: Cliente) {

    let tarjetaPagoEncriptada = this.crypto.encryptData(
      cliente.tarjetaPago,
      'smart2020pay');

    let fd = new HttpParams()
      .set('email', cliente.email)
      .set('tipoCliente', cliente.tipoCliente)
      .set('razonSocial', cliente.razonSocial)
      .set('cif', cliente.cif)
      .set('telefono1', cliente.telefono1)
      .set('nombreComercial', cliente.nombreComercial)
      .set('clave', cliente.clave)
      .set('direccionFacturacion', cliente.direccionFacturacion)
      .set('tarjetaPago', tarjetaPagoEncriptada);

    if (cliente.telefono2) {
      fd = fd.append('telefono2', cliente.telefono2);
    }
    if (cliente.web) {
      fd = fd.append('web', cliente.web);
    }
    if (cliente.codigoPromocional) {
      fd = fd.append('codigoPromocional', cliente.codigoPromocional);
    }
    if (cliente.iban) {
      fd = fd.append('iban', cliente.iban);
    }
    if (cliente.contacto) {
      if (cliente.contacto.email) {
        fd = fd.append('emailContacto', cliente.contacto.email);
      }
      if (cliente.contacto.nombre) {
        fd = fd.append('nombreContacto', cliente.contacto.nombre);
      }
    }
    return this.makePostRequest(this.pathSmartBiometrik + 'sb/register/company', fd)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  /**
   * Función temporal para editar y activar clientes de manera automática
   * @param cliente 
   */
  public editarCliente_temporal(cliente: Cliente) {
    let fd = new HttpParams()
      .set('idAdministrador', '5e43bbb1cce0d1001204a156') // server
    // La siguiente línea corresponde al id de un administrador en mi servidor local
    // .set('idAdministrador', '5e15d16e9143a207505eb050');
    // .set('idAdministrador', '5e7217e61ea525001978830a'); // César

    if (cliente.estado) {
      fd = fd.append('estado', cliente.estado);
    }
    if (cliente.premium) {
      fd = fd.append('premium', cliente.premium);
    }

    return this.makePostRequest(this.pathSmartBiometrik + 'sb/edit/company/' + cliente._id, fd)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }


  public editarCliente(cliente: Cliente, admin: boolean, campos?: any) {

    let fd = new HttpParams();
    if (admin) {
      fd = fd.append('idAdministrador', '5e43bbb1cce0d1001204a156'); // server
      // fd = fd.append('idAdministrador', '5e15d16e9143a207505eb050'); // Javi
      // fd = fd.append('idAdministrador', '5e7217e61ea525001978830a'); // César
    }
    //Si recibimos el atributo "campos" entonces sólo enviamos esos atributos para editar.
    if (campos) {
      if (campos.idCliente) {
        fd = fd.append('idCliente', campos.idCliente);
      }
      if (campos.email) {
        fd = fd.append('email', campos.email);
      }
      if (campos.estado) {
        fd = fd.append('estado', campos.estado);
      }
      if (campos.sinConfirmarEmail == false || campos.sinConfirmarEmail == true) {
        fd = fd.append('sinConfirmarEmail', campos.sinConfirmarEmail);
      }
      if (campos.tipoCliente) {
        fd = fd.append('tipoCliente', campos.tipoCliente);
      }
      if (campos.razonSocial) {
        fd = fd.append('razonSocial', campos.razonSocial);
      }
      if (campos.cif) {
        fd = fd.append('cif', campos.cif);
      }
      if (campos.telefono1) {
        fd = fd.append('telefono1', campos.telefono1);
      }
      if (campos.telefono2) {
        fd = fd.append('telefono2', campos.telefono2);
      }
      if (campos.nombreComercial) {
        fd = fd.append('nombreComercial', campos.nombreComercial);
      }
      if (campos.clave) {
        fd = fd.append('clave', campos.clave);
      }
      if (campos.claveAntigua) {
        fd = fd.append('claveAntigua', campos.claveAntigua);
      }
      if (campos.direccionFacturacion) {
        fd = fd.append('direccionFacturacion', campos.direccionFacturacion);
      }
      if (campos.web) {
        fd = fd.append('web', campos.web);
      }
      if (campos.iban) {
        fd = fd.append('iban', campos.iban);
      }
      if (campos.idAdministrador) {
        fd = fd.append('idAdministrador', campos.idAdministrador);
      }
      if (campos.tarjetaPago) {
        fd = fd.append('tarjetaPago', campos.tarjetaPago);
      }
      if (campos.logo) {
        fd = fd.append('logo', campos.logo);
      }
      if (campos.plantillas) {
        fd = fd.append('plantillas', campos.plantillas);
      }
      if (campos.eventos) {
        fd = fd.append('eventos', campos.eventos);
      }
      if (campos.nombreContacto) {
        fd = fd.append('nombreContacto', campos.nombreContacto);
      }
      if (campos.centros) {
        fd = fd.append('centros', campos.centros);
      }
      if (campos.administradores) {
        fd = fd.append('administradores', campos.administradores);
      }
      if (campos.token) {
        fd = fd.append('token', campos.token);
      }
      if (campos.codigoPromocional) {
        fd = fd.append('codigoPromocional', campos.codigoPromocional);
      }
      if (campos.proveedor) {
        fd = fd.append('proveedor', campos.proveedor);
      }
      if (campos.bajas) {
        fd = fd.append('bajas', campos.bajas);
      }
      if (campos.passLink) {
        fd = fd.append('passLink', campos.passLink);
      }
      if (campos.customer) {
        fd = fd.append('customer', campos.customer);
      }
      if (campos.card) {
        fd = fd.append('card', campos.card);
      }
      if (campos.plan) {
        fd = fd.append('plan', campos.plan);
      }
      if (campos.subscripcion) {
        fd = fd.append('subscripcion', campos.subscripcion);
      }
    } else {
      //Si no se reciben los campos por separados, entonces cogemos los datos del cliente.
      if (cliente._id) {
        fd = fd.append('idCliente', cliente._id);
      }
      if (cliente.email) {
        fd = fd.append('email', cliente.email);
      }
      if (cliente.estado) {
        fd = fd.append('estado', cliente.estado);
      }
      if (cliente.sinConfirmarEmail == false || cliente.sinConfirmarEmail == true) {
        fd = fd.append('sinConfirmarEmail', cliente.sinConfirmarEmail.toString());
      }
      if (cliente.tipoCliente) {
        fd = fd.append('tipoCliente', cliente.tipoCliente);
      }
      if (cliente.razonSocial) {
        fd = fd.append('razonSocial', cliente.razonSocial);
      }
      if (cliente.cif) {
        fd = fd.append('cif', cliente.cif);
      }
      if (cliente.telefono1) {
        fd = fd.append('telefono1', cliente.telefono1);
      }
      if (cliente.telefono2) {
        fd = fd.append('telefono2', cliente.telefono2);
      }
      if (cliente.nombreComercial) {
        fd = fd.append('nombreComercial', cliente.nombreComercial);
      }
      if (cliente.clave) {
        fd = fd.append('clave', cliente.clave);
      }
      if (cliente.claveAntigua) {
        fd = fd.append('claveAntigua', cliente.claveAntigua);
      }
      if (cliente.direccionFacturacion) {
        fd = fd.append('direccionFacturacion', cliente.direccionFacturacion);
      }
      if (cliente.web) {
        fd = fd.append('web', cliente.web);
      }
      if (cliente.iban) {
        fd = fd.append('iban', cliente.iban);
      }
      if (cliente.tarjetaPago) {
        let tarjetaPagoEncriptada = this.crypto.encryptData(cliente.tarjetaPago, 'smart2020pay');
        fd = fd.append('tarjetaPago', tarjetaPagoEncriptada);
      }
      if (cliente.logo) {
        fd = fd.append('logo', cliente.logo._id);
      }
      if (cliente.contacto) {
        if (cliente.contacto.nombre) {
          fd = fd.append('nombreContacto', cliente.contacto.nombre);
        }
        if (cliente.contacto.email) {
          fd = fd.append('nombreContacto', cliente.contacto.email);
        }
      }
      if (cliente.token) {
        fd = fd.append('token', cliente.token);
      }
      if (cliente.codigoPromocional) {
        fd = fd.append('codigoPromocional', cliente.codigoPromocional);
      }
      if (cliente.proveedor) {
        fd = fd.append('proveedor', cliente.proveedor._id);
      }
      if (cliente.passLink) {
        fd = fd.append('passLink', cliente.passLink);
      }
      if (cliente.stripe) {
        if (cliente.stripe.customer) {
          fd = fd.append('customer', cliente.stripe.customer);
        }
        if (cliente.stripe.card) {
          fd = fd.append('card', cliente.stripe.card);
        }
        if (cliente.stripe.plan) {
          fd = fd.append('plan', cliente.stripe.plan);
        }
        if (cliente.stripe.subscripcion) {
          fd = fd.append('subscripcion', cliente.stripe.subscripcion);
        }
      }
    }

    return this.makePostRequest(this.pathSmartBiometrik + 'sb/edit/company', fd)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  public modificarCliente(cliente: Cliente) {
    let fd = new HttpParams()
      .set('idAdministrador', '5e43bbb1cce0d1001204a156') // server
      // La siguiente línea corresponde al id de un administrador en mi servidor local
      // .set('idAdministrador', '5e15d16e9143a207505eb050');

      //.set('idAdministrador', '5e7217e61ea525001978830a') // César

      .set('razonSocial', cliente.razonSocial)
      .set('telefono1', cliente.telefono1)
      .set('telefono2', cliente.telefono2)
      .set('nombreComercial', cliente.nombreComercial)
      .set('direccionFacturacion', cliente.direccionFacturacion)
      .set('web', cliente.web)
      .set('contacto.nombre', cliente.contacto.nombre)
      .set('contacto.email', cliente.contacto.email)
      .set('clave', cliente.clave)



    return this.makePostRequest(this.pathSmartBiometrik + 'sb/edit/company/' + cliente._id, fd)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  public editarClienteStripe(cliente: Cliente, tipo: string) {

    // //console.log('Cliente: ', cliente)

    let tarjetaPagoEncriptada = this.crypto.encryptData(
      cliente.tarjetaPago,
      'smart2020pay');

    let fd = new HttpParams()

      .set('idAdministrador', '5e43bbb1cce0d1001204a156') // server

      //.set('idAdministrador', '5e7217e61ea525001978830a') // César

      .set('stripe.customer', cliente.stripe.customer)
      .set('stripe.card', cliente.stripe.card)
      .set('stripe.plan', cliente.stripe.plan)
      .set('stripe.subscripcion', cliente.stripe.subscripcion)
      .set('tarjetaPago', tarjetaPagoEncriptada)
    /*
         if (tipo === 'subscripcion') {
           fd = fd.append('subscripcion.plan', cliente.subscripcion.plan)
           fd = fd.append('subscripcion.contadorFirmas', cliente.subscripcion.contadorFirmas.toString())
           fd = fd.append('subscripcion.estado', cliente.subscripcion.estado)
           fd = fd.append('subscripcion.fechaInicio', cliente.subscripcion.fechaInicio.toString())
           fd = fd.append('subscripcion.fechaRenovacion', cliente.subscripcion.fechaRenovacion.toString())
          }
     */


    return this.makePostRequest(this.pathSmartBiometrik + 'sb/edit/company/' + cliente._id, fd)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  public subirArchivo(body) {
    return this.makePostRequest('https://dashboard.becheckin.com/php/fileUploadHotel.php', body)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  public borrarArchivo(formData) {

    return this.makePostRequestRaw('https://dashboard.becheckin.com/php/deleteimage.php', formData)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  public sendGenericMail(asunto, mensaje, mailTo, cc?, cco?, firma?: boolean) {

    if (firma) {
      //           <img src="https://firebasestorage.googleapis.com/v0/b/smartbiometrik-6257b.appspot.com/o/dashboard%2Flogofirma.png?alt=media&token=ff172506-3849-4578-8730-8819b7659a4d" height="100" width="307">
      mensaje = mensaje + `
                 <hr />
                 <p style="text-align: justify; font-size: 10px; font-style: italic;">Este mensaje y los adjuntos pueden contener información confidencial, no estando permitida su comunicación, reproducción o distribución. Si usted no es el destinatario final, le rogamos nos lo comunique y borre el mismo. De conformidad con el que dispone la normativa vigente en protección de datos RGPD y LOPDGDD, le informamos que los datos personales serán tratados bajo la responsabilidad de SMARTBIOMETRIK. Puede ejercer los derechos de acceso, rectificación, portabilidad, supresión, limitación y oposición mandando un mensaje a info@smartbiometrik.com. Si considera que el tratamiento no se ajusta a la normativa vigente, podrá presentar una reclamación ante la autoridad de control en agpd.es. Para más información puede consultar nuestra política de privacidad en https://smartbiometrik.com/politica-privacidad/</p>
                 <p style="text-align: justify; font-size: 10px; font-style: italic;">This message and the attachments may contain confidential information, their communication, reproduction or distribution is not allowed. If you are not the final recipient, please let us know and delete the same. In accordance with the provisions of the current RGPD and LOPDGDD data protection regulations, we inform you that personal data will be processed under the responsibility of SMARTBIOMETRIK. You can exercise the rights of access, rectification, portability, deletion, limitation and opposition by sending a message to info@smartbiometrik.com. If you believe that the treatment does not conform to current regulations, you may file a complaint with the control authority at agpd.es. For more information you can consult our privacy policy at https://smartbiometrik.com/politica-privacidad/</p>`;
    }

    let fd = new HttpParams()
      .set('asunto', asunto)
      .set('mensaje', mensaje)
      .set('mailTo', mailTo);

    if (cc) {
      fd = fd.append('cc', cc);
    }
    if (cco) {
      fd = fd.append('cco', cco);
    }

    return this.makePostRequest(this.pathSmartBiometrik + 'sb/sendEmail', fd)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  public sendGenericMailWithAttachment(asunto, mensaje, mailTo, link, cc?, cco?, firma?: boolean) {

    if (firma) {
      //           <img src="https://firebasestorage.googleapis.com/v0/b/smartbiometrik-6257b.appspot.com/o/dashboard%2Flogofirma.png?alt=media&token=ff172506-3849-4578-8730-8819b7659a4d" height="100" width="307">
      mensaje = mensaje + `
                <hr />
                <p style="text-align: justify; font-size: 10px; font-style: italic;">Este mensaje y los adjuntos pueden contener información confidencial, no estando permitida su comunicación, reproducción o distribución. Si usted no es el destinatario final, le rogamos nos lo comunique y borre el mismo. De conformidad con el que dispone la normativa vigente en protección de datos RGPD y LOPDGDD, le informamos que los datos personales serán tratados bajo la responsabilidad de SMARTBIOMETRIK. Puede ejercer los derechos de acceso, rectificación, portabilidad, supresión, limitación y oposición mandando un mensaje a info@smartbiometrik.com. Si considera que el tratamiento no se ajusta a la normativa vigente, podrá presentar una reclamación ante la autoridad de control en agpd.es. Para más información puede consultar nuestra política de privacidad en https://smartbiometrik.com/politica-privacidad/</p>
                <p style="text-align: justify; font-size: 10px; font-style: italic;">This message and the attachments may contain confidential information, their communication, reproduction or distribution is not allowed. If you are not the final recipient, please let us know and delete the same. In accordance with the provisions of the current RGPD and LOPDGDD data protection regulations, we inform you that personal data will be processed under the responsibility of SMARTBIOMETRIK. You can exercise the rights of access, rectification, portability, deletion, limitation and opposition by sending a message to info@smartbiometrik.com. If you believe that the treatment does not conform to current regulations, you may file a complaint with the control authority at agpd.es. For more information you can consult our privacy policy at https://smartbiometrik.com/politica-privacidad/</p>`;
    }

    let fd = new HttpParams()
      .set('asunto', asunto)
      .set('mensaje', mensaje)
      .set('mailTo', mailTo)
      .set('from', 'noreply@smartbiometrik.com')
      .set('link', link);

    if (cc) {
      fd = fd.append('cc', cc);
    }
    if (cco) {
      fd = fd.append('cco', cco);
    }

    return this.makePostRequest(this.pathSmartBiometrik + 'sb/sendEmailwithFile', fd)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  // Conexión con API de Microsoft AZURE
  public detectionMicrosoft(url) {

    let fd = new HttpParams()
      .set('url', url);

    return this.makePostRequest(this.pathSmartBiometrik + 'azure/detection', fd)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  public verificationMicrosoft(faceId1, faceId2) {

    let fd = new HttpParams()
      .set('faceId1', faceId1)
      .set('faceId2', faceId2);

    return this.makePostRequest(this.pathSmartBiometrik + 'azure/verification', fd)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  public crearNuevoUsuario(usuario: Usuario) {

    let fd = new HttpParams()
      .set('estado', usuario.estado)
      .set('email', usuario.email)
      .set('idDocumento', usuario.idDocumento)
      .set('tipoDocumento', usuario.tipoDocumento)
      .set('nombre', usuario.nombre)
      .set('apellido1', usuario.apellido1)
      .set('apellido2', usuario.apellido2)
      .set('fechaNacimiento', usuario.fechaNacimiento)
      .set('fechaExpedicion', usuario.fechaExpedicion)
      .set('firma', usuario.firma)
      .set('genero', usuario.genero)
      .set('provincia', usuario.provincia)
      .set('pais', usuario.pais)
      .set('verificacionEmail', String(usuario.verificacionEmail))
      .set('porcentajeVerificacion', usuario.porcentajeVerificacion.toString())
      .set('codeEmail', usuario.codeEmail)
      .set('tipoUsuario', usuario.tipoUsuario)
      .set('azureFaceId', usuario.azureFaceId)
      .set('azureDocumentId', usuario.azureDocumentId)

    if (usuario.imagenes) {
      fd = fd.append('imagenes', JSON.stringify(usuario.imagenes));
    }

    return this.makePostRequest(this.pathSmartBiometrik + 'sb/users', fd)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  public editarUsuario(usuario: Usuario) {

    let fd = new HttpParams();

    if (usuario) {
      if (usuario.email) {
        fd = fd.append('email', usuario.email);
      }
      if (usuario.idDocumento) {
        fd = fd.append('idDocumento', usuario.idDocumento);
      }
      if (usuario.tipoDocumento) {
        fd = fd.append('tipoDocumento', usuario.tipoDocumento);
      }
      if (usuario.nombre) {
        fd = fd.append('nombre', usuario.nombre);
      }
      if (usuario.apellido1) {
        fd = fd.append('apellido1', usuario.apellido1);
      }
      if (usuario.apellido2) {
        fd = fd.append('apellido2', usuario.apellido2);
      }
      if (usuario.fechaNacimiento) {
        fd = fd.append('fechaNacimiento', usuario.fechaNacimiento);
      }
      if (usuario.fechaExpedicion) {
        fd = fd.append('fechaExpedicion', usuario.fechaExpedicion);
      }
      if (usuario.genero) {
        fd = fd.append('genero', usuario.genero);
      }
      if (usuario.firma) {
        fd = fd.append('firma', usuario.firma);
      }
      if (usuario.provincia) {
        fd = fd.append('provincia', usuario.provincia);
      }
      if (usuario.verificacionEmail) {
        fd = fd.append('verificacionEmail', usuario.verificacionEmail.toString());
      }
      if (usuario.porcentajeVerificacion) {
        fd = fd.append('porcentajeVerificacion', usuario.porcentajeVerificacion.toString());
      }
      if (usuario.codeEmail) {
        fd = fd.append('codeEmail', usuario.codeEmail);
      }
      if (usuario.azureDocumentId) {
        fd = fd.append('azureDocumentId', usuario.azureDocumentId);
      }
      if (usuario.azureFaceId) {
        fd = fd.append('azureFaceId', usuario.azureFaceId);
      }
      if (usuario.imagenes) {
        fd = fd.append('imagenes', JSON.stringify(usuario.imagenes));
      }
    }

    return this.makePostRequest(this.pathSmartBiometrik + 'sb/users/' + usuario._id, fd)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  public obtenerEventoDeEnlace(idEnlace: string) {

    let fd = new HttpParams();

    return this.makeGetRequest(this.pathSmartBiometrik + 'sb/enlaces/' + idEnlace, fd)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  public crearNuevaPlantilla(plantilla: Plantilla) {

    let fd = new HttpParams()
      .set('nombre', plantilla.nombre)
      .set('enlaceArchivo', plantilla.archivo.enlace)
      .set('nombreArchivo', plantilla.archivo.nombre)
      .set('tipoArchivo', plantilla.archivo.tipo)
      .set('contenidoArchivo', plantilla.archivo.contenido)

    return this.makePostRequest(this.pathSmartBiometrik + 'sb/create/template/' + plantilla.idCliente, fd)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  public obtenerPlantilla(idPlantilla: string) {

    let fd = new HttpParams();

    return this.makeGetRequest(this.pathSmartBiometrik + 'sb/template/' + idPlantilla, fd)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  public crearNuevoEvento(evento: Evento, idPlantilla: string, idEmisor: string, file: File) {

    let fd = new FormData();
    fd.append('estado', evento.estado);
    fd.append('tipo', evento.tipo);
    fd.append('cliente', idEmisor);
    fd.append('titulo', evento.titulo);
    fd.append('descripcion', evento.descripcion ? evento.descripcion : "");
    fd.append('idPlantilla', idPlantilla);
    fd.append('contadorfirmas', evento.contadorfirmas.toString());
    fd.append('firmaCliente', evento.firmaCliente.toString());
    fd.append('mensaje', evento.mensaje ? evento.mensaje : "");
    fd.append('file', file, file.name + ".pdf");

    // if (evento.tipo != 'enlace') {
    //   fd.append('idReceptor', evento.idReceptor._id);
    // }

    return this.makePostRequestMultipart(this.pathSmartBiometrik + 'sb/create/multievent', fd)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  public autoFirma(idEvento: string, file: File) {

    let fd = new FormData();
    fd.append('idEvento', idEvento);
    fd.append('file', file, file.name + ".pdf");

    return this.makePostRequestMultipart(this.pathSmartBiometrik + 'sb/autoFirma', fd)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  public editarEvento(evento: Evento, campos?: any) {

    let fd = new HttpParams();
    //Si recibimos el atributo "campos" entonces sólo enviamos esos atributos para editar.
    if (campos) {
      if (campos.estado) {
        fd = fd.append('estado', campos.estado);
      }
      if (campos.tipo) {
        fd = fd.append('tipo', campos.tipo);
      }
      if (campos.idReceptor) {
        fd = fd.append('idReceptor', campos.idReceptor._id);
      }
      if (campos.idEnlace) {
        fd = fd.append('idEnlace', campos.idEnlace._id);
      }
      if (campos.idEmisor) {
        fd = fd.append('idEmisor', campos.idEmisor._id);
      }
      if (campos.idPlantilla) {
        fd = fd.append('idPlantilla', campos.idPlantilla);
      }
      if (campos.titulo) {
        fd = fd.append('titulo', campos.titulo);
      }
      if (campos.descripcion) {
        fd = fd.append('descripcion', campos.descripcion);
      }
      if (campos.mensaje) {
        fd = fd.append('mensaje', campos.mensaje);
      }
      if (campos.urlFirma) {
        fd = fd.append('urlFirma', campos.urlFirma);
      }
      if (campos.contadorfirmas) {
        fd = fd.append('contadorfirmas', campos.contadorfirmas.toString());
      }
      if (campos.pago) {
        if (campos.pago.id) {
          fd = fd.append('idPago', campos.pago.id);
        }
        if (campos.pago.precio) {
          fd = fd.append('precioPago', campos.pago.precio);
        }
        if (campos.pago.estado) {
          fd = fd.append('estadoPago', campos.pago.estado);
        }
        if (campos.pago.clientSecret) {
          fd = fd.append('clientSecretPago', campos.pago.clientSecret);
        }
      }
    } else {
      if (evento.estado) {
        fd = fd.append('estado', evento.estado);
      }
      if (evento.tipo) {
        fd = fd.append('tipo', evento.tipo);
      }
      if (evento.idReceptor) {
        fd = fd.append('idReceptor', evento.idReceptor._id);
      }
      if (evento.idEnlace) {
        fd = fd.append('idEnlace', evento.idEnlace._id);
      }
      if (evento.idEmisor) {
        fd = fd.append('idEmisor', evento.idEmisor._id);
      }
      if (evento.idPlantilla) {
        fd = fd.append('idPlantilla', evento.idPlantilla);
      }
      if (evento.titulo) {
        fd = fd.append('titulo', evento.titulo);
      }
      if (evento.descripcion) {
        fd = fd.append('descripcion', evento.descripcion);
      }
      if (evento.mensaje) {
        fd = fd.append('mensaje', evento.mensaje);
      }
      if (evento.urlFirma) {
        fd = fd.append('urlFirma', evento.urlFirma);
      }
      if (evento.contadorfirmas) {
        fd = fd.append('contadorfirmas', evento.contadorfirmas.toString());
      }
      if (evento.pago) {
        if (evento.pago.id) {
          fd = fd.append('idPago', evento.pago.id);
        }
        if (evento.pago.precio) {
          fd = fd.append('precioPago', evento.pago.precio.toString());
        }
        if (evento.pago.estado) {
          fd = fd.append('estadoPago', evento.pago.estado);
        }
        if (evento.pago.clientSecret) {
          fd = fd.append('clientSecretPago', evento.pago.clientSecret);
        }
      }
    }

    return this.makePostRequest(this.pathSmartBiometrik + 'sb/edit/event/' + evento._id, fd)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  public editarEnlace(idEnlace: string, idEvento: string, estado: string, idUsuario?: string) {

    let fd = new HttpParams()
      .set('idEvento', idEvento)
      .set('estado', estado);

    if (idUsuario) {
      fd = fd.append('idUsuario', idUsuario);
    }

    return this.makePostRequest(this.pathSmartBiometrik + 'sb/edit/enlace/' + idEnlace, fd)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  public crearLink(email) {
    let body = {
      email: email
    }
    return this.makePostRequest(this.pathSmartBiometrik + 'sb/pass/createLink', body)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  public eraseLink(link, clave) {
    let body = {
      link: link,
      clave: clave
    }
    return this.makePostRequest(this.pathSmartBiometrik + 'sb/pass/eraseLink', body)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  public checkLink(link) {
    let body = {
      link: link
    }
    return this.makePostRequest(this.pathSmartBiometrik + 'sb/pass/checkLink', body)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  encryptdata(key, data) {

    let body = {
      key: key,
      data: data
    }
    return this.makePostRequest(this.pathSmartBiometrik + 'sb/encryptdata', body)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  decryptdata(key, data) {

    let body = {
      key: key,
      data: data
    }
    return this.makePostRequest(this.pathSmartBiometrik + 'sb/decryptdata', body)
      .then(res => {
        console.log('Decrypt: ', res)
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  createPayment(card, email) {

    let body = {
      cardNumber: card.number,
      cardMonth: card.month,
      cardYear: card.year,
      cardCvc: card.cvc,
      email: email
    }

    return this.makePostRequest(this.pathSmartBiometrik + 'sb/stripe/createPayment', body)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  createCustomer(payment, email) {

    let body = {
      payment: payment,
      email: email
    }

    return this.makePostRequest(this.pathSmartBiometrik + 'sb/stripe/createCustomer', body)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  createSubscription(plan, customer, codigo) {

    let body: any;

    if (codigo !== 'ninguno') {
      body = {
        plan: plan,
        customer: customer,
        coupon: codigo
      }
    } else {
      body = {
        plan: plan,
        customer: customer
      }
    }


    return this.makePostRequest(this.pathSmartBiometrik + 'sb/stripe/createSubscriptionWithCoupon', body)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  updateSubscription(plan, subscripcion, codigo) {

    let body: any;

    if (codigo !== 'ninguno') {
      body = {
        plan: plan,
        subscripcion: subscripcion,
        coupon: codigo
      }
    } else {
      body = {
        plan: plan,
        subscripcion: subscripcion
      }
    }


    return this.makePostRequest(this.pathSmartBiometrik + 'sb/stripe/updateSubscription', body)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  cancelSubscription(subscripcion) {

    let body = {
      subscripcion: subscripcion
    }

    return this.makePostRequest(this.pathSmartBiometrik + 'sb/stripe/cancelSubscription', body)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  getCountEvents(cliente, fecha) {

    let body = {
      cliente: cliente,
      fecha: fecha
    }

    return this.makePostRequest(this.pathSmartBiometrik + 'sb/events/getCountEvents', body)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  public getPlans() {
    const fd = new HttpParams();
    return this.makeGetRequest(this.pathSmartBiometrik + 'sb/stripe/plan', '')
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  public getSubcription(subscripcion) {
    const fd = new HttpParams();
    return this.makeGetRequest(this.pathSmartBiometrik + 'sb/stripe/getSubscription/' + subscripcion, '')
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  public getCoupons() {
    const fd = new HttpParams();
    return this.makeGetRequest(this.pathSmartBiometrik + 'sb/stripe/coupons', '')
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  borrarCliente(cliente) {

    let body = {
      cliente: cliente

    }

    return this.makePostRequest(this.pathSmartBiometrik + 'sb/client/delete', body)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  public borrarPlantilla(idPlantilla, idCliente) {
    let body = {
      idCliente: idCliente,
      idPlantilla: idPlantilla
    }
    return this.makePostRequest(this.pathSmartBiometrik + 'sb/delete/template', body)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  public cancelarEvento(idEvento, idCliente) {
    let body = {
      idCliente: idCliente,
      idEvento: idEvento
    }
    return this.makePostRequest(this.pathSmartBiometrik + 'sb/cancel/event', body)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  public actualizarArchivo(archivo, enlace, nombre) {

    let body = {
      archivo: archivo,
      enlace: enlace,
      nombre: nombre
    }

    return this.makePostRequest(this.pathSmartBiometrik + 'sb/archivo/actualizar', body)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  public finalizarEvento(idEvento, archivo, enlace, nombre) {

    let body = {
      archivo: archivo,
      enlace: enlace,
      nombre: nombre,
      idEvento: idEvento
    }

    return this.makePostRequest(this.pathSmartBiometrik + 'sb/finish/event', body)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  public verificarDocumentoDeEvento(identificador: string, archivo: File) {

    let fd = new FormData();
    fd.append('identificador', identificador);
    fd.append('archivo', archivo, archivo.name);

    return this.makePostRequestMultipart(this.pathSmartBiometrik + 'sb/event/verify', fd)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  public crearNuevaCuentaStripeConnect(cliente: Cliente, pais: string) {

    let fd = new FormData();
    fd.append('pais', pais);
    fd.append('prod', "true");

    return this.makePostRequest(this.pathSmartBiometrik + 'sb/stripe/createCustomAccount/' + cliente._id, fd)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  public obtenerCuentaStripeConnect(idStripeConnect: string) {

    let fd = new FormData();
    fd.append('prod', "true");

    return this.makePostRequest(this.pathSmartBiometrik + 'sb/stripe/retrieveAccount/' + idStripeConnect, fd)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  public obtenerCarpetasCliente(idCliente: string) {

    let fd = new FormData();

    return this.makeGetRequest(this.pathSmartBiometrik + 'sb/digitalFile/getClientFolders/' + idCliente, fd)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  public obtenerCarpetaPorId(idCarpeta: string) {

    let fd = new FormData();

    return this.makeGetRequest(this.pathSmartBiometrik + 'sb/digitalFile/getFolder/' + idCarpeta, fd)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  public crearNuevaCarpeta(idCliente: string, idCarpetaPadre: string, nombre: string, creador: string, idCheckList: string) {

    let fd = new FormData();
    if (idCarpetaPadre && idCarpetaPadre != "-") {
      fd.append('idCarpetaPadre', idCarpetaPadre);
    }
    if (idCheckList && idCheckList != "-") {
      fd.append('idCheckList', idCheckList);
    }
    fd.append('idCliente', idCliente);
    fd.append('nombre', nombre);
    fd.append('creador', creador);

    return this.makePostRequest(this.pathSmartBiometrik + 'sb/digitalFile/createFolder/', fd)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  public editarCarpeta(idCliente: string, idCarpeta: string, datos: any) {

    let fd = new FormData();
    fd.append('idCliente', idCliente);
    fd.append('idCarpeta', idCarpeta);
    if (datos.nombre) {
      fd.append('nombre', datos.nombre);
    }
    if (datos.compartido) {
      fd.append('compartido', datos.compartido);
    }
    if (datos.privada) {
      fd.append('privada', datos.privada);
    }
    if (datos.favorito) {
      fd.append('favorito', datos.favorito);
    }
    if (datos.anclado) {
      fd.append('anclado', datos.anclado);
    }
    if (datos.idCarpetaPadre) {
      fd.append('idCarpetaPadre', datos.idCarpetaPadre);
    }
    if (datos.fechaApertura) {
      fd.append('fechaApertura', datos.fechaApertura);
    }
    if (datos.borrado) {
      fd.append('borrado', datos.borrado);
    }

    //Edición de checkList en carpeta
    if (datos.actionCheckList) {
      switch (datos.actionCheckList) {
        case "edit":
          if (datos.tituloCheckList) {
            fd.append('tituloCheckList', datos.tituloCheckList);
          }
          if (datos.descripcionCheckList) {
            fd.append('descripcionCheckList', datos.descripcionCheckList);
          }
          if (datos.idPuntoCheckList) {
            fd.append('idPuntoCheckList', datos.idPuntoCheckList);
            if (datos.tituloPuntoCheckList) {
              fd.append('tituloPuntoCheckList', datos.tituloPuntoCheckList);
            }
            if (datos.iconoPuntoCheckList) {
              fd.append('iconoPuntoCheckList', datos.iconoPuntoCheckList);
            }
            if (datos.opcionalPuntoCheckList) {
              fd.append('opcionalPuntoCheckList', datos.opcionalPuntoCheckList);
            }
            if (datos.completadoPuntoCheckList) {
              fd.append('completadoPuntoCheckList', datos.completadoPuntoCheckList);
            }
          }

          break;

        case "deletePoint":
          if (datos.idPuntoCheckList) {
            fd.append('idPuntoCheckList', datos.idPuntoCheckList);
          }

          break;

        case "deleteCheckList":

          break;

        case "addPoint":
          if (datos.tituloPuntoCheckList) {
            fd.append('tituloPuntoCheckList', datos.tituloPuntoCheckList);
          }
          if (datos.iconoPuntoCheckList) {
            fd.append('iconoPuntoCheckList', datos.iconoPuntoCheckList);
          }
          if (datos.opcionalPuntoCheckList) {
            fd.append('opcionalPuntoCheckList', datos.opcionalPuntoCheckList);
          }
          if (datos.completadoPuntoCheckList) {
            fd.append('completadoPuntoCheckList', datos.completadoPuntoCheckList);
          }

          break;

        case "addCheckList":
          if (datos.idCheckList) {
            fd.append('idCheckList', datos.idCheckList);
          }

          break;

        default:
          break;
      }
    }

    return this.makePostRequest(this.pathSmartBiometrik + 'sb/digitalFile/editFolder/', fd)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  public eliminarCarpeta(idCliente: string, idCarpeta: string) {

    let fd = new FormData();
    fd.append('idCliente', idCliente);
    fd.append('idCarpeta', idCarpeta);

    return this.makePostRequest(this.pathSmartBiometrik + 'sb/digitalFile/confirmDeleteFolder/', fd)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  public crearNuevoDocumento(idCliente: string, idCarpeta: string, nombre: string, creador: string, file: File, tipo?: string, idPunto?: string) {

    let fd = new FormData();
    fd.append('idCliente', idCliente);
    fd.append('idCarpeta', idCarpeta);
    fd.append('nombre', nombre);
    fd.append('creador', creador);
    fd.append('archivo', file, file.name);
    if (tipo) {
      fd.append('tipo', tipo);
    }
    if (idPunto) {
      fd.append('idPunto', idPunto);
    }

    return this.makePostRequestMultipart(this.pathSmartBiometrik + 'sb/digitalFile/createDocument', fd)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  public obtenerDocumentosCarpeta(idCarpeta: string) {

    let fd = new FormData();

    return this.makeGetRequest(this.pathSmartBiometrik + 'sb/digitalFile/getDocumentsByFolderId/' + idCarpeta, fd)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  public obtenerDocumentoPorId(idDocumento: string) {

    let fd = new FormData();

    return this.makeGetRequest(this.pathSmartBiometrik + 'sb/digitalFile/getDocument/' + idDocumento, fd)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  public editarDocumento(idDocumento: string, numeroFirmantes: string, firmaCliente: string, tipoFirma: string, firmarDocumento: string) {

    let fd = new FormData();
    fd.append('idDocumento', idDocumento);
    if (numeroFirmantes) {
      fd.append('numeroFirmantes', numeroFirmantes);
    }
    if (firmaCliente) {
      fd.append('firmaCliente', firmaCliente);
    }
    if (tipoFirma) {
      fd.append('tipoFirma', tipoFirma);
    }
    if (firmarDocumento) {
      fd.append('firmarDocumento', firmarDocumento);
    }

    return this.makePostRequest(this.pathSmartBiometrik + 'sb/digitalFile/editDocument/', fd)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  public eliminarDocumento(idCliente: string, idDocumento: string) {

    let fd = new FormData();
    fd.append('idCliente', idCliente);
    fd.append('idDocumento', idDocumento);

    return this.makePostRequest(this.pathSmartBiometrik + 'sb/digitalFile/confirmDeleteDocument/', fd)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  public eliminarCheckList(idCheckList: string) {

    let fd = new FormData();

    return this.makeDeleteRequest(this.pathSmartBiometrik + 'sb/digitalFile/deleteCheckList/' + idCheckList, fd)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  public crearNuevaCheckListPredefinida(titulo: string, descripcion: string, predefinida: string, puntos: PuntoCheckList[]) {

    let body = {
      titulo: titulo,
      descripcion: descripcion,
      predefinida: predefinida,
      puntos: puntos
    }

    return this.makePostRequest(this.pathSmartBiometrik + 'sb/digitalFile/createDefaultCheckList', body)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  public obtenerCheckListsPredefinidas() {

    let fd = new FormData();

    return this.makeGetRequest(this.pathSmartBiometrik + 'sb/digitalFile/getDefaultCheckLists/', fd)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  public obtenerCheckListsPredefinidasPorCliente(idCliente: string) {

    let fd = new FormData();
    fd.append('idCliente', idCliente);

    return this.makePostRequest(this.pathSmartBiometrik + 'sb/digitalFile/getDefaultCheckListsByClient/', fd)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  public obtenerCheckListPorId(idCheckList: string) {

    let fd = new FormData();

    return this.makeGetRequest(this.pathSmartBiometrik + 'sb/digitalFile/getDefaultCheckList/' + idCheckList, fd)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  public autoLoginClient(tokenProveedor: string, tokenCliente: string) {

    let fd = new FormData();
    fd.append('tokenProveedor', tokenProveedor);
    fd.append('tokenCliente', tokenCliente);

    return this.makePostRequest(this.pathSmartBiometrik + 'sb/client/autoLogin', fd)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  public crearNuevoPago(idCliente, concepto, nota, precio) {

    let fee = Math.round((precio * 0.05) + Number.EPSILON) + 25;
    let body = {
      idCliente: idCliente,
      concepto: concepto,
      nota: nota,
      tipo: "pago",
      estado: "pendiente",
      firmaCliente: false,
      pago: {
        payment_method_types: [
          "card"
        ],
        amount: precio,
        currency: "eur",
        application_fee_amount: fee,
      },
      prod: true
    }

    return this.makePostRequestMultipart(this.pathSmartBiometrik + 'sb/stripe/createCharge', body)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  public crearNuevoPayout(idCliente, concepto, nota, precio) {

    let body = {
      idCliente: idCliente,
      concepto: concepto,
      nota: nota,
      tipo: "pago",
      estado: "pendiente",
      firmaCliente: false,
      pago: {
        source_type: [
          "card"
        ],
        amount: precio,
        currency: "eur"
        // application_fee_amount: 1005,
      },
      prod: true
    }

    return this.makePostRequestMultipart(this.pathSmartBiometrik + 'sb/stripe/createCharge', body)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  cancelarPagoStripe(idEvento) {

    let body = {
      idEvento: idEvento,
      prod: true
    }

    return this.makePostRequest(this.pathSmartBiometrik + 'sb/stripe/cancelPaymentIntent', body)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  compartirCarpetaPorEmail(idCliente: string, idCarpeta: string, correos: string[]) {

    let body = {
      idCliente: idCliente,
      idCarpeta: idCarpeta,
      correos: correos
    }

    return this.makePostRequest(this.pathSmartBiometrik + 'sb/digitalFile/shareFolderByEmail', body)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }

  shareTextByEmail(asunto: string, mensaje: string, correos, firma?: boolean) {

    if (firma) {
      mensaje = mensaje + `
                <hr />
                <p style="text-align: justify; font-size: 10px; font-style: italic;">Este mensaje y los adjuntos pueden contener información confidencial, no estando permitida su comunicación, reproducción o distribución. Si usted no es el destinatario final, le rogamos nos lo comunique y borre el mismo. De conformidad con el que dispone la normativa vigente en protección de datos RGPD y LOPDGDD, le informamos que los datos personales serán tratados bajo la responsabilidad de SMARTBIOMETRIK. Puede ejercer los derechos de acceso, rectificación, portabilidad, supresión, limitación y oposición mandando un mensaje a info@smartbiometrik.com. Si considera que el tratamiento no se ajusta a la normativa vigente, podrá presentar una reclamación ante la autoridad de control en agpd.es. Para más información puede consultar nuestra política de privacidad en https://smartbiometrik.com/politica-privacidad/</p>
                <p style="text-align: justify; font-size: 10px; font-style: italic;">This message and the attachments may contain confidential information, their communication, reproduction or distribution is not allowed. If you are not the final recipient, please let us know and delete the same. In accordance with the provisions of the current RGPD and LOPDGDD data protection regulations, we inform you that personal data will be processed under the responsibility of SMARTBIOMETRIK. You can exercise the rights of access, rectification, portability, deletion, limitation and opposition by sending a message to info@smartbiometrik.com. If you believe that the treatment does not conform to current regulations, you may file a complaint with the control authority at agpd.es. For more information you can consult our privacy policy at https://smartbiometrik.com/politica-privacidad/</p>`;
    }

    let body = {
      asunto: asunto,
      mensaje: mensaje,
      correos: correos
    }

    return this.makePostRequest(this.pathSmartBiometrik + 'sb/shareTextByEmail', body)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        //console.log('Error: ' + error);
        return Promise.reject(error);
      });
  }
}