import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({ 
    providedIn: 'root', 
})
export class GoogleCloudVisionServiceProvider {

    constructor(public httpClient: HttpClient) { }

    getLabels(base64Image) {
        const body = {
            "requests": [
                {
                    "image": {
                        "content": base64Image
                    },
                    "features": [
                        {
                            "type": "DOCUMENT_TEXT_DETECTION"
                        }
                    ]
                }
            ]
        }

        return this.httpClient.post('https://vision.googleapis.com/v1/images:annotate?key=' + environment.googleCloudVisionAPIKey, body);
    }

    getTextFromPDF(nombreFichero) {
        const body = {
            "requests":[
              {
                "inputConfig": {
                  "gcsSource": {
                    "uri": "gs://smartbiometrik-7f6d5.appspot.com/demoPDF3.pdf"
                  },
                  "mimeType": "application/pdf"
                },
                "features": [
                  {
                    "type": "DOCUMENT_TEXT_DETECTION"
                  }
                ],
                "outputConfig": {
                  "gcsDestination": {
                    "uri": "gs://smartbiometrik-7f6d5.appspot.com/temp_dest/"
                  },
                  "batchSize": 2
                }
              }
            ]
        }

        return this.httpClient.post('https://vision.googleapis.com/v1/files:asyncBatchAnnotate?key=' + environment.googleCloudVisionAPIKey, body);
    }

    getAll(base64Image) {
        const body = {
            "requests": [
                {
                    "image": {
                        "content": base64Image
                    },
                    "features": [
                        {
                            "maxResults": 50,
                            "type": "LANDMARK_DETECTION"
                        },
                        {
                            "maxResults": 50,
                            "type": "FACE_DETECTION"
                        },
                        {
                            "maxResults": 50,
                            "type": "OBJECT_LOCALIZATION"
                        },
                        {
                            "maxResults": 50,
                            "type": "LOGO_DETECTION"
                        },
                        {
                            "maxResults": 50,
                            "type": "LABEL_DETECTION"
                        },
                        {
                            "maxResults": 50,
                            "type": "DOCUMENT_TEXT_DETECTION"
                        },
                        {
                            "maxResults": 50,
                            "type": "SAFE_SEARCH_DETECTION"
                        },
                        {
                            "maxResults": 50,
                            "type": "IMAGE_PROPERTIES"
                        },
                        {
                            "maxResults": 50,
                            "type": "CROP_HINTS"
                        },
                        {
                            "maxResults": 50,
                            "type": "WEB_DETECTION"
                        }
                    ]
                },
            ]
        }

        return this.httpClient.post('https://vision.googleapis.com/v1/images:annotate?key=' + environment.googleCloudVisionAPIKey, body);
    }
}