import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from '../../i18n/en';
import { locale as spanish } from '../../i18n/es';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { fuseAnimations } from '@fuse/animations';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { UtilityProvider } from 'app/providers/utility/utility';
import { TranslateService } from '@ngx-translate/core';
import { DataManagement } from 'app/services/dataManagement';
import { GlobalService } from 'app/services/globalService';

@Component({
  selector: 'share-email',
  templateUrl: './share-email.component.html',
  styleUrls: ['./share-email.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class ShareEmailComponent implements OnInit {

  correos: FormArray;
  loading: boolean;
  emailForm: FormGroup;
  tipo: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    public dialogRef: MatDialogRef<FuseConfirmDialogComponent>,
    private utils: UtilityProvider,
    private translate: TranslateService,
    private dm: DataManagement,
    private globalService: GlobalService
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, spanish);
    // Set the defaults
    this.emailForm = this.createEmailForm();
  }

  ngOnInit(): void {
    this.loading = false;
    this.correos = this.emailForm.get("correos") as FormArray;
    if (this.data.otherData.idCarpeta) {
      this.tipo = "compartirCarpeta";
    } else if (this.data.asunto && this.data.text) {
      this.tipo = "compartirTexto";
    }
  }

  ngAfterContentInit() {

  }

  /**
   * Create send form
   *
   * @returns {FormGroup}
   */
  createEmailForm(): FormGroup {
    return new FormGroup({
      correos: new FormArray([
        new FormGroup({
          correo: new FormControl('', [Validators.required, checkEmailValidator])
        })
      ]),
    });
  }

  addEmail() {
    this.correos.push(this.newEmail());
  }

  newEmail(): FormGroup {
    return new FormGroup({
      correo: new FormControl('', [Validators.required, checkEmailValidator])
    })
  }

  removeEmail(index) {
    if (this.correos.length > 1) {
      this.correos.removeAt(index);
    }
  }

  // eventHandler(event) {
  //   if (event.keyCode == 13) {
  //     this.enviarMensaje();
  //   }
  // }

  async enviarMensaje() {
    console.log(this.correos);
    if (this.correos.controls.length < 1) {
      return;
    }
    let correosValidos = true;
    this.correos.controls.forEach(formGroup => {
      if (formGroup.status != 'VALID') {
        correosValidos = false;
      }
    })
    if (correosValidos) {
      // this.loading = true;
      // this.loading = false;
      try {
        if (this.tipo == "compartirCarpeta") {
          let resCompartirCarpetaPorEmail = await this.dm.compartirCarpetaPorEmail(this.globalService.cliente._id, this.data.otherData.idCarpeta, this.correos.value);
          if (!resCompartirCarpetaPorEmail || resCompartirCarpetaPorEmail.status != 'ok') {
            console.log("resCompartirCarpetaPorEmail", resCompartirCarpetaPorEmail);
            throw "Fallo en la petición";
          }
        } else if (this.tipo == "compartirTexto") {
          let resCompartirTextoPorEmail = await this.dm.shareTextByEmail(this.data.asunto, this.data.text, this.correos.value, true);
          if (!resCompartirTextoPorEmail || resCompartirTextoPorEmail.status != 'ok') {
            console.log("resCompartirCarpetaPorEmail", resCompartirTextoPorEmail);
            throw "Fallo en la petición";
          }
        } else {
          throw "Operación desconocida";
        }
        this.utils.openDialog(this.utils.SUCCESS, this.translate.instant("DIGITAL_FILE.DIALOGS.SHARE_EMAIL.SHARED_SUCCESSFULLY"), false, false);
        this.dialogRef.close();
      } catch (error) {
        console.log(error);
        this.utils.openDialog(this.utils.WARNING, this.translate.instant("DIGITAL_FILE.DIALOGS.SHARE_EMAIL.UNAVAILABLE_SERVICE"), false, false);
      }
    } else {
      this.utils.openDialog(this.utils.WARNING, this.translate.instant("DIGITAL_FILE.DIALOGS.SHARE_EMAIL.EMAIL_ERROR"), false, false);
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ FUNCIONES AUXILIARES
  // -----------------------------------------------------------------------------------------------------

}

/**
 * Check email validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const checkEmailValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

  if (!control.parent || !control) {
    return null;
  }

  const email = control.value;

  let regex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
  if (regex.test(email)) {
    return null;
  }

  return { emailNotMatching: true };
};