// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    hmr       : false,
    googleCloudVisionAPIKey: 'AIzaSyDdbwKIidkrbWqIFafIyRsnciO_gA8g7og',
    firebase: {
      apiKey: "AIzaSyCP5gRaKQMF60r5E2nyT-SB2baosXkSGKY",
      authDomain: "smartbiometrik-6257b.firebaseapp.com",
      databaseURL: "https://smartbiometrik-6257b.firebaseio.com",
      projectId: "smartbiometrik-6257b",
      storageBucket: "smartbiometrik-6257b.appspot.com",
      messagingSenderId: "613693291709",
      appId: "1:613693291709:web:f0bf3597e74626fca3cbd4",
      measurementId: "G-67TPKP0TPM"
    },
    // socketUrl: 'http://localhost:8080/'
    socketUrl: 'https://api.smartbiometrik.com/'
    // socketUrl: 'https://apiface.smartbiometrik.com/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
