import { Injectable } from '@angular/core';
import { CryptProvider } from '../crypt/crypt';
import * as jsPDF from 'jspdf';
import { PDF } from 'app/models/others.model';

@Injectable()
export class BuildPDFProvider {

    constructor(
        private crypto: CryptProvider
    ) { }

    /**
     * Función que dado un contenido encriptado, y una clave de desencriptación, devuelve un doc en jsPDF
     * @param contenido 
     */
    public obtenerDocumento(contenido, key): any {

        let contenidoDesencriptado = this.crypto.decryptData(contenido, key);

        let contenidoPDF = new PDF(contenidoDesencriptado);
        let doc = new jsPDF();

        //Recorremos función por función añadiendo todo el contenido
        for (let funcion of contenidoPDF.funciones) {

            switch (funcion.nombre) {
                case "text":
                    if (funcion.rellenable) {
                        if (funcion.rellenable.estado) {
                            doc.text(funcion.rellenable.valor, funcion.atributos[1], funcion.atributos[2]);
                        }
                    } else {
                        doc.text(funcion.atributos[0], funcion.atributos[1], funcion.atributos[2]);
                    }
                    break;

                default:
                    break;
            }

        }
        return doc;
    }
}