export const locale = {
    lang: 'es',
    data: {
        'SHARE_WHATSAPP': {
            'TITLE': 'Compartir por WhatsApp',
            'USE_CUSTOM_NUMBER': 'No tengo el número en mis contactos',
            'CHOOSE_NUMBER': 'Elige los contactos directamente desde WhatsApp',
            'WRITE_NUMBER': 'Escribe un número de teléfono',
            'ADDITIONAL_INFO': '*Debes incluir el prefijo del país',
            'PHONE_NUMBER': '+00 000 000 000',
            'BACK': 'Volver',
            'SEND': 'Enviar',
        },
      
    }
};
