import { Injectable } from '@angular/core';
import AES from "crypto-js/aes";
import CryptoJS from "crypto-js/crypto-js";

@Injectable()
export class CryptProvider {

    constructor() { }

    /**
     * Función para encriptar datos
     * @param contenido El contenido debe ir en formato JSON
     * @param key 
     */
    public encryptData(contenido, key): string {
        //Primero comprimimos el JSON, luego encriptamos, y por ultimo codificamos para enviar.
        let encriptado = encodeURIComponent(AES.encrypt(JSON.stringify(contenido), key));
        return encriptado;
    }

    /**
     * Función para desencriptar datos.
     * @param contenido 
     * @param key 
     */
    public decryptData(contenido, key): any {
        //Primero decodificamos, luego desencriptamos el contenido, y por último parseamos la información a JSON.
        var bytes = AES.decrypt(decodeURIComponent(contenido), key)
        try {
          let res = JSON.parse(bytes.toString(CryptoJS.enc.Utf8)) 
          return res; 
        }
        catch {
          let res = 'Error'
          return res;
        }
        
    }

  
}


