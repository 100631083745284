import { FuseUtils } from '@fuse/utils';
import { ClienteForm } from './form.model';

export class Cookies {
    cliente: string;
}

export class Usuario {
    _id: string;
    estado: string;
    email: string;
    idDocumento: string;
    tipoDocumento: string;
    nombre: string;
    apellido1: string;
    apellido2: string;
    fechaNacimiento: string;
    fechaExpedicion: string;
    firma: string;
    genero: string;
    imagenes: Archivo[];
    provincia: string;
    pais: string;
    verificacionEmail: boolean;
    documentoConVerificacionFacial: boolean;
    porcentajeVerificacion: number;
    codeEmail: string;
    clave: string;
    tipoUsuario: string;
    azureFaceId: string;
    azureDocumentId: string;
    premium: string;
    eventos: Evento[];
    subscripciones: Cliente[];
    iban: string;
    telefono: string;
}

export class GrupoUsuarios {
    correo: string;
    usuarios: Usuario[];
}

export class Cliente {
    _id: string;
    estado: string;
    email: string;
    tipoCliente: string;
    razonSocial: string;
    cif: string;
    telefono1: string;
    telefono2: string;
    nombreComercial: string;
    clave: string;
    direccionFacturacion: string;
    web: string;
    logo: Archivo;
    iban: string;
    plantillas: Plantilla[];
    eventos: Evento[];
    subscriptores: Usuario[];
    codigoPromocional: string;
    contacto: ContactoCliente;
    proveedor: Proveedor;
    token: string;
    tarjetaPago: Tarjeta;
    bajas: Baja[];
    passLink: string;
    stripe: {
        customer: string,
        card: string,
        plan: string,
        subscripcion: string,
        customAccount: string
    }
    fechaCreacion: Date;
    fechaActualizacion: Date;
    sinConfirmarEmail: boolean;
    carpetas: Carpeta[];
    firmaOneShot: boolean;

    //Atributos que sólo se usan en el panel de control
    claveAntigua: string;

    //Atributos que todavía no están en uso
    centros: Centro[];
    administradores: Empleado[];

    //Atributos obsoletos
    /**
     * @deprecated
     */
    premium: string;
    /**
     * @deprecated
     */
    fechaPremium: string;
    /**
     * @deprecated
     */
    subscripcion: {
        plan: Plan,
        contadorFirmas: Number,
        fechaInicio: Date,
        fechaRenovacion: Date,
        estado: string
    }

    /**
     * Constructor de cliente a partir de formulario de registro.
     * @param clienteForm 
     */
    constructorRegistro(clienteForm: ClienteForm) {
        this.email = clienteForm.email;
        this.clave = clienteForm.clave;
        this.razonSocial = clienteForm.razonSocial;
        this.cif = clienteForm.cif;
        this.telefono1 = clienteForm.telefono1;
        this.telefono2 = clienteForm.telefono2;
        this.nombreComercial = clienteForm.nombreComercial;
        this.direccionFacturacion = clienteForm.direccionFacturacion;
        this.web = clienteForm.web;
        this.codigoPromocional = clienteForm.codigoPromocional;
        this.tarjetaPago = new Tarjeta();
        this.tarjetaPago.numero = clienteForm.numeroTarjeta;
        this.tarjetaPago.mes = clienteForm.mesTarjeta;
        this.tarjetaPago.anyo = clienteForm.anyoTarjeta;
        this.tarjetaPago.cvv = clienteForm.cvvTarjeta;
        this.tipoCliente = clienteForm.tipoCliente;
        this.contacto = new ContactoCliente();
        this.contacto.email = clienteForm.contactoEmail;
        this.contacto.nombre = clienteForm.contactoNombre;
        this.sinConfirmarEmail = true;
    }
}

export class Carpeta {
    _id: string;
    nombre: string;
    tipo: string;
    compartido: boolean;
    privada: boolean;
    codigoAcceso: string;
    infoCompartida: InformacionCompartida[];
    anclado: boolean;
    favorito: boolean;
    creador: string;
    idCarpetaPadre: string;
    carpetasHijas: Carpeta[];
    documentos: DocumentoCarpeta[];
    checkList: CheckList;
    fechaApertura: Date;
    fechaCreacion: Date;
    fechaActualizacion: Date;
    borrado: boolean;

    //Atributos utilizados sólo en front-end
    enlaceCopiado: boolean;
    cuentaNumeros: Number;
    compruebaCuenta: any;
}

export class InformacionCompartida {
    correo: string;
    codigoPrivado: string;
    facial: string;
    privacidad: boolean;
}

export class DocumentoCarpeta{
    _id: string;
    nombre: string;
    tipo: string;
    creador: string;
    formato: string;
    compartido: boolean;
    firmar: boolean;
    evento: Evento;
    peso: string;
    fechaApertura: Date;
    fechaCreacion: Date;
    fechaActualizacion: Date;
    borrado: boolean;

    //Atributos utilizados sólo en front-end
    enlaceCopiado: boolean;
}

export class CheckList {
    _id: string;
    predeterminado: boolean;
    titulo: string;
    descripcion: string;
    puntos: PuntoCheckList[];
}

export class PuntoCheckList {
    _id: string;
    titulo: string;
    icono: string;
    opcional: boolean;
    completado: boolean;
    documento: DocumentoCarpeta;
    documentos: DocumentoCarpeta[];
}

export class Subscription {
    _id: string;
    stripeId: string;
    nombre: string;
    limiteFirmas: number;
    fechaActualizacion: Date;
    fechaCreacion: Date;
    fechaRenovacion: Date;
}

export class Plan {
    _id: string;
    planId: string;
    nombre: string;
    numeroFirmas: number;
    precio: number;
    comentario: string;
    stripe: number;
}

export class Proveedor {
    _id: string;
    estado: string;
    email: string;
    nombre: string;
    clave: string;
    token: string;
    tipoPremium: string;
    mesesPremium: number;
    urlNotificaciones: string;
}

export class Centro {
    _id: string;
    nombre: string;
    espaciosDisponibles: number;
    latitud: string;
    longitud: string;
    margenEntradaPorDefecto: number;
    margenSalidaPorDefecto: number;
    horaSalidaPorDefecto: number;
    empleados: Empleado[];
}

export class Empleado {
    _id: string;
    estado: string;
    email: string;
    idDocumento: string;
    tipoDocumento: string;
    nombre: string;
    apellido1: string;
    apellido2: string;
    fechaNacimiento: string;
    firma: string;
    genero: string;
    imagenes: Archivo[];
    provincia: string;
    pais: string;
    verificacionEmail: string;
    porcentajeVerificacion: number;
    codigoVerificacion: string;
    clave: string;
    permisos: string;
    azureFaceId: string;
    azureDocumentId: string;
    eventos: Evento[];
    telefono: string;
    margenEntrada: number;
    trabajos: Trabajo[];
    descansos: Descanso[];
}

export class Trabajo {
    _id: string;
    horaEntrada: Date;
    horaSalida: Date;
    nota: string;
    latitud: string;
    longitud: string;
}

export class Descanso {
    _id: string;
    diaInicio: Date;
    diaFin: Date;
    tipo: string;
}

export class Tarjeta {
    numero: string;
    mes: number;
    anyo: number;
    cvv: number;
}

export class ContactoCliente {
    email: string;
    nombre: string;
}

export class Evento {
    _id: string;
    version: number;
    estado: string;
    tipo: string;
    proyecto: string;
    idReceptor: Usuario;
    idEnlace: Enlace;
    idEmisor: Cliente;
    idPlantilla: string;
    titulo: string;
    descripcion: string;
    archivo: Archivo;
    mensaje: string;
    fechaCreacion: Date;
    fechaActualizacion: Date;
    urlFirma: string;
    contadorfirmas: number;
    firmaCliente: boolean;
    firmas: Firma[];
    pago: Pago;
    // Atributos para front-end
    cuentaNumeros: number;
    copiado: boolean;
    compruebaCuenta: any;
    addNote: boolean;
    notaPrevia: string;
    id: number;
}

export class Pago {
    id: string;
    precio: number;
    estado: string;
    fechaCreacion: Date;
    fechaPago: Date;
    clientSecret: string;
    email: string;
    nombreCompleto: string;
    numeroRecibo: string;
    // Atributos para front-end
    paymentIntent: any;
}

export class Firma {
    _id: string;
    usuario: Usuario;
    imagenes: Archivo[];
    estado: String;
    fecha: Date;
    correo: String;
    orden: number;
    verificacionFacial: Boolean;
    porcentajeVerificacionFacial: number;
}

export class Enlace {
    _id: string;
    fechaCreacion: Date;
    estado: string;
    usuario: Usuario;
    correo: string;
    verificacionFacial: boolean;
}

export class Plantilla {
    _id: string;
    idCliente: string;
    nombre: string;
    version: number;
    archivo: Archivo;
    fechaActualizacion: Date;
    fechaCreacion: Date;
    id: number; //El id es un atributo que sólo se utiliza en front-end para identificar más facilmente cada documento.
    numeroEnvios: number; // el numeroEnvios es un atributo que sólo se utilizan en front-end para saber el número de veces que se ha enviado.
    borrado: boolean;

    constructor(plantilla?) {
        plantilla = plantilla || {};
        this._id = plantilla._id || "";
        this.idCliente = plantilla.idCliente || "";
        this.nombre = plantilla.nombre || "";
        this.version = plantilla.version || 0;
        this.archivo = plantilla.archivo || null;
        this.fechaActualizacion = plantilla.fechaActualizacion || null;
        this.fechaCreacion = plantilla.fechaCreacion || null;
        this.id = plantilla.id || 0;
        this.numeroEnvios = plantilla.numeroEnvios || 0;
        this.borrado = false;
    }
}

export class Archivo {
    _id: string;
    nombre: string;
    enlace: string;
    contenido: string;
    tipo: string;
    base64: string;
    fechaActualizacion: Date;
    fechaCreacion: Date;
}

export class Baja {
    _id: string;
    motivo: string;
    sugerencia: string;
    fechaBaja: Date;
    fechaAlta: Date;
}

export class PagoStripe {
    object: string;
    amount: number;
    arrival_date: number;
    automatic: boolean;
    balance_transaction: string;
    created: number;
    currency: string;
    description: string;
    destination: string;
    failure_balance_transaction: string;
    failure_code: string;
    failure_message: string;
    livemode: string;
    metadata: Object;
    method: string;
    source_type: string;
    statement_descriptor: string;
    status: string;
    type: string;
}

// ENUMERADOS
export enum TipoCliente {
    EMPRESA = "empresa",
    NORMAL = "normal",
    ADMINISTRADOR = "administrador"
}
export enum TipoPremium {
    FREE = "free",
    BASIC = "basic",
    ENTERPRISE = "enterprise"
}
export enum EstadoUsuario {
    ACTIVO = "activo",
    INACTIVO = "inactivo",
    ELIMINADO = "eliminado"
}
export enum TipoSeccion {
    PRINCIPAL = "principal",
    SIN_PERMISO = "sin_permiso",
    FIRMANDO = "firmando",
    FIRMADO = "firmado"
}
export enum PasoFirma {
    TIPO_DOCUMENTO = "tipoDocumento",
    ESCANEAR_DNI = "escanearDocumentoDni",
    ESCANEAR_PASAPORTE = "escanearDocumentoPasaporte",
    ESCANEAR_DNI_2 = "escanearDocumentoDni2",
    ESCANEAR_FACIAL = "escanearFacial",
    FIRMA_DIGITAL = "firmaDigital",
    REGISTRO_COMPLETADO = "registroCompletado"
}