import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';

@Injectable({
  providedIn: 'root'
})
export class FirebaseStorageService {

  constructor(
    private storage: AngularFireStorage
  ) { }

  //Tarea para subir archivo
  public subirArchivo(nombreArchivo: string, datos: any) {
    return this.storage.upload(nombreArchivo, datos);
  }

  public borrarArchivo(nombreArchivo: string) {
    return this.storage.ref(nombreArchivo).delete();
  }

  public descargarArchivo(nombreArchivo: string) {
    return this.storage.ref(nombreArchivo).getDownloadURL();
  }


  //Referencia del archivo
  public referencia(nombreArchivo: string) {
    return this.storage.ref(nombreArchivo);
  }

  

}