<div *ngIf="data.otherData">
    <h1 *ngIf="data.title=='success'" class="title" mat-dialog-title>
        <i class="material-icons">check_circle_outline</i>
        {{'DIALOG.SUCCESS' | translate}}
    </h1>
    <h1 *ngIf="data.title=='warning'" class="title" mat-dialog-title>
        <i class="material-icons">warning</i>
        {{'DIALOG.WARNING' | translate}}
    </h1>
    <h1 *ngIf="data.title=='error'" class="title" mat-dialog-title>
        <i class="material-icons">error_outline</i>
        {{'DIALOG.ERROR' | translate}}
    </h1>
    <h1 *ngIf="data.title=='info'" class="title" mat-dialog-title>
        <i class="material-icons">info</i>
        {{'DIALOG.WARNING' | translate}}
    </h1>
    <h1 *ngIf="data.title!='success' && data.title!='warning' && data.title!='error'" class="title_center" mat-dialog-title>
        {{ data.title }}
    </h1>
    <div *ngIf='!data.otherData.traducir' class="message" mat-dialog-content layout="row" layout-align="center center" flex>
        <p style="text-align: justify;">
            {{ data.message }}
        </p>
    </div>

    <div  *ngIf='data.otherData.traducir' class="message" mat-dialog-content layout="row" layout-align="center center" flex>
        <p style="text-align: justify;">
            {{ data.message | translate }}
        </p>
    </div>
    
    <div *ngIf="data.otherData.textodata" class="message" mat-dialog-content layout="row" layout-align="center center" flex>
        <p style="text-align: justify;">
           <strong>{{ data.otherData.textodata | translate }}</strong>
        </p>
    </div>
    
    <div *ngIf="data.otherData.tarjeta" class="message" mat-dialog-content layout="row" layout-align="center center" flex>
        <p style="text-align: justify;">
           <strong>{{ 'DIALOG.TARJETA' | translate }}</strong> {{ 'DIALOG.CARD' | translate }} {{ data.otherData.tarjeta }}
        </p>
    </div>

    <div *ngIf="data.otherData.razonSocial" class="message" mat-dialog-content layout="row" layout-align="center center" flex>
        <p style="text-align: justify;">
          {{ data.otherData.razonSocial | translate }}
        </p>
    </div>

    <div *ngIf="data.otherData.cif" class="message" mat-dialog-content layout="row" layout-align="center center" flex>
        <p style="text-align: justify;">
          {{ data.otherData.cif | translate }}
        </p>
    </div>
    
    <div *ngIf="data.otherData.direccion" class="message" mat-dialog-content layout="row" layout-align="center center" flex>
        <p style="text-align: justify;">
          {{ data.otherData.direccion | translate }}
        </p>
    </div>

    <div *ngIf="data.otherData.telefono1" class="message" mat-dialog-content layout="row" layout-align="center center" flex>
        <p style="text-align: justify;">
          Telefono1: {{ data.otherData.telefono1 }}
        </p>
    </div>

    <div *ngIf="data.otherData.telefono2" class="message" mat-dialog-content layout="row" layout-align="center center" flex>
        <p style="text-align: justify;">
          Telefono2: {{ data.otherData.telefono2 }}
        </p>
    </div>

    <div *ngIf="data.otherData.contacto" class="message" mat-dialog-content layout="row" layout-align="center center" flex>
        <p style="text-align: justify;">
          Contacto: {{ data.otherData.contacto }}
        </p>
    </div>

    <div *ngIf="data.otherData.subscripcion" class="message" mat-dialog-content layout="row" layout-align="center center" flex>
        <p style="text-align: justify;">
           {{ data.otherData.subscripcion }}
        </p>
    </div>
    <div *ngIf="data.otherData.plan" class="message" mat-dialog-content layout="row" layout-align="center center" flex>
        <p style="text-align: justify;">
          {{ data.otherData.plan }}
        </p>
    </div>



    <div *ngIf="data.otherData.importe" class="message" mat-dialog-content layout="row" layout-align="center center" flex>
        <p style="text-align: justify;">
            <strong>{{ 'DIALOG.IMPORTE' | translate}}</strong>{{ data.otherData.importe }} {{'DIALOG.IVA' | translate }}
        </p>
    </div>

    <div *ngIf="data.otherData.renovacion" class="message" mat-dialog-content layout="row" layout-align="center center" flex>
        <p style="text-align: justify;">
          <strong>{{ 'DIALOG.RENOVACION' | translate}}</strong>
        </p>
    </div>
    
    <div *ngIf="data.otherData.codigoPromocional" class="message" mat-dialog-content layout="row" layout-align="center center" flex>
        <input type="text" class="minput" placeholder="Código Promocional" [(ngModel)]="data.otherData.codigo">
    </div>
    
    <div *ngIf="data.otherData.check" class="message" mat-dialog-content layout="row" layout-align="center center" flex>
        <input type="checkbox" style="margin-right: 5px" name="accept" [(ngModel)]="data.otherData.checked">
        <span>{{ 'DIALOG.CONFIRMAR' | translate }}</span>
    </div>

    <div *ngIf="data.otherData.condiciones" class="message" mat-dialog-content layout="row" layout-align="center center" flex>
        <input type="checkbox" style="margin-right: 5px" name="accept" [(ngModel)]="data.otherData.checked">
        <a href="assets/pdf/politica-privacidad.pdf"
        target="_blank">{{'DIALOG.ACEPTAR_TERMINOS' | translate }}</a>

    </div>
    <br>
    
    <div class="buttons">
    
        <button *ngIf="data.accept" style="margin-right: 5px" mat-button (click)="dialogRef.close(false)">{{'DIALOG.CANCEL' | translate}}</button>
        
        <button [disabled] = "!data.otherData.checked" *ngIf="data.textAccept && data.otherData.checked" mat-raised-button class="mat-accent" (click)="dialogRef.close(true)"
            cdkFocusInitial>{{ data.textAccept | translate }}</button>
        
        <button *ngIf="data.otherData.boton && !data.otherData.checked" mat-raised-button class="mat-accent" (click)="dialogRef.close('ir')"
            cdkFocusInitial>{{ data.otherData.botontexto | translate }}</button>
         
        <button *ngIf="data.otherData.codigoPromocional" mat-raised-button class="mat-accent" (click)="dialogRef.close(data.otherData.codigo)"
            cdkFocusInitial>{{ data.otherData.botontexto  | translate}}</button>
        
        <button *ngIf="!data.textAccept && !data.otherData.codigoPromocional" mat-raised-button class="mat-accent" (click)="dialogRef.close(true)"
            >{{'DIALOG.ACCEPT' | translate}}</button>
       
    </div>
</div>

<div *ngIf="!data.otherData">
    <h1 *ngIf="data.title=='success'" class="title" mat-dialog-title>
        <i class="material-icons">check_circle_outline</i>
        {{'DIALOG.SUCCESS' | translate}}
    </h1>
    <h1 *ngIf="data.title=='warning'" class="title" mat-dialog-title>
        <i class="material-icons">warning</i>
        {{'DIALOG.WARNING' | translate}}
    </h1>
    <h1 *ngIf="data.title=='error'" class="title" mat-dialog-title>
        <i class="material-icons">error_outline</i>
        {{'DIALOG.ERROR' | translate}}
    </h1>
   
    <h1 *ngIf="data.title!='success' && data.title!='warning' && data.title!='error'" class="title_center" mat-dialog-title>
        {{ data.title }}
    </h1>
    <div class="message" mat-dialog-content layout="row" layout-align="center center" flex>
        <p style="text-align: justify;">
            {{ data.message }}
        </p>
    </div>
    
    
    <div class="buttons">
    
        <button *ngIf="data.accept" mat-button (click)="dialogRef.close(false)">{{'DIALOG.BACK' | translate}}</button>
        
        <button *ngIf="data.textAccept" mat-raised-button class="mat-accent" (click)="dialogRef.close(true)"
            cdkFocusInitial>{{ data.textAccept }}</button>
        
        
        <button *ngIf="!data.textAccept" mat-raised-button class="mat-accent" (click)="dialogRef.close(true)"
            >{{'DIALOG.ACCEPT' | translate}}</button>
       
    </div>
</div>
