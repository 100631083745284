export const locale = {
    lang: 'en',
    data: {
        'DIALOG': {
            'SUCCESS': 'Success',
            'WARNING': 'Warning',
            'ERROR': 'Error',
            'CANCEL': 'Cancel',
            'BACK': 'Back',
            'ACCEPT': 'Accept',
            'INFO': 'Info',
            'TARJETA': "Payment Method",
            'FACTURACION': "Billing Data",
            'TERMINOS': "Terms and Conditions",
            'CODIGO': 'Promotional Code',
            'CARD': 'Card',
            'RENOVACION':'Monthly Renewal',
            'IMPORTE':'Total Amount',
            'CONFIRMAR':'Confirm Data',
            'ACEPTAR_TERMINOS':'I accept the terms and conditions',
            'IVA':' € (VAT included)'

            
        }
    }
};
