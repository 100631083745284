export const locale = {
    lang: 'es',
    data: {
        'SHARE_IDCALL': {
            'TITLE': 'Firmar con SBK Identity',
            'ADMIN_ACCESS': 'Acceder como administrador',
            'ADMIN_ACCESS_DESCRIPTION': 'Podrás habilitar y finalizar la firma durante la videollamada.',
            'USER_LINK': 'Compartir enlace con otros usuarios',
            'DESCRIPTION': 'Desde este enlace de SBK Identity podrán visualizar el documento y firmarlo.',
            'COPIED_LINK': 'Copiado',
            'COPY_LINK': 'Copiar enlace',
            'BACK': 'volver'
        },
      
    }
};
